import { DateField, DateFieldReadOnly } from 'src/components_generic/DateField'
import { NumberField, NumberFieldReadOnly } from 'src/components_generic/NumberField'
import { NumberFormatterFactory } from 'src/components_generic/number-formatter'
import { VisibleIf } from 'src/components_generic/visible-if'
import i18n from 'src/producers/languages/i18n'
import styles from "../../../../../styles.module.css"
import { ReactNumberInput } from 'src/components_generic/ReactNumberInput'

const NumberFormatter = NumberFormatterFactory()

export const RentalPeriod: view = ({
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status
}) => {
  const isActiveContract = contractStatus === "active";
  const displayReadOnlyView = isActiveContract || isLocked || true;

  return <>
    <VisibleIf predicate={!displayReadOnlyView}>
      <RentalPeriodParkingComponent/>
    </VisibleIf>
    <VisibleIf predicate={displayReadOnlyView}>
      <RentalPeriodParkingComponentReadOnly/>
    </VisibleIf>
  </>
}

enum RentalPeriodParkingInputFields {
  RENTAL_START = 'rentalStart',
  RENTAL_END = 'rentalEnd',
  NOTICE_PERIOD = 'noticePeriod',
  RENTAL_PAYMENT_START = 'rentalPaymentStart'
}

const RentalPeriodParkingComponent: view = ({
  fields = observe.contract.originalv2.fields,
  setChanges = update.contract.changes.fields[param.name],
  setMiscChanges = update.contract.changes.miscellaneous[param.name]
}) => {
  // if (!contract?.metadata?.readOnly) return null
  const RentalStartDate = () => fields?.[RentalPeriodParkingInputFields.RENTAL_START]?.value;
  const RentalEndDate = () => fields?.[RentalPeriodParkingInputFields.RENTAL_END]?.value;
  const RentalPaymentStart = () => fields?.[RentalPeriodParkingInputFields.RENTAL_PAYMENT_START]?.value;

  return <div>
    <h2>3. {i18n.t(`contract.general.rental_period`)}</h2>

    <div className={`${styles.cardSection}`}>
      <div className="flex flex-row items-center justify-start gap-8">
        {i18n.t(`contract.general.rental_period_description`)}
      </div>

      <div className={`${styles.row}`}>
        <DateField
          name={RentalPeriodParkingInputFields.RENTAL_START}
          label={i18n.t(`contract.labels.${RentalPeriodParkingInputFields.RENTAL_START}`)}
          value={RentalStartDate()}
          required={true}
          onChange={value => {
            setMiscChanges.set({
              value
            }, {
              name: RentalPeriodParkingInputFields.RENTAL_START
            })
          }}
        />
        <DateField
          name={RentalPeriodParkingInputFields.RENTAL_END}
          label={i18n.t(`contract.labels.${RentalPeriodParkingInputFields.RENTAL_END}`)}
          value={RentalEndDate()}
          onChange={value => {
            setMiscChanges.set({
              value
            }, {
              name: RentalPeriodParkingInputFields.RENTAL_END
            })
          }}
        />
        <DateField
          name={RentalPeriodParkingInputFields.RENTAL_PAYMENT_START}
          label={i18n.t(`contract.labels.${RentalPeriodParkingInputFields.RENTAL_PAYMENT_START}`)}
          value={RentalPaymentStart()}
          onChange={value => {
            setMiscChanges.set({
              value
            }, {
              name: RentalPeriodParkingInputFields.RENTAL_PAYMENT_START
            })
          }}
        />
        <ReactNumberInput
          decimalScale={0}
          label={i18n.t(`contract.labels.${RentalPeriodParkingInputFields.NOTICE_PERIOD}`)}
          name={RentalPeriodParkingInputFields.NOTICE_PERIOD}
          required={true}
          value={(fields?.[RentalPeriodParkingInputFields.NOTICE_PERIOD]?.value || null)}
          placeholder={i18n.t(`contract.placeholders.${RentalPeriodParkingInputFields.NOTICE_PERIOD}`)}
          onChange={value => setChanges.set({ value }, {
            name: RentalPeriodParkingInputFields.NOTICE_PERIOD
          })}
        />   
      </div>
    </div>
  </div>
}

const RentalPeriodParkingComponentReadOnly: view = ({
  contract = observe.contract.originalv2,
  fields = observe.contract.originalv2.fields
}) => {
  return <div>
    <h2>{i18n.t(`contract.general.rental_period`)}</h2>

    <div className={`${styles.cardSection}`}>
      <div className="flex flex-row items-center justify-start gap-8">
        {i18n.t(`contract.general.rental_period_description`)}
      </div>

      <div className={`${styles.row}`}>
        <DateFieldReadOnly
          extraClassNames='flex-grow'
          // name={RentalPeriodParkingInputFields.RENTAL_START}
          label={i18n.t(`contract.labels.${RentalPeriodParkingInputFields.RENTAL_START}`)}
          value={fields?.[RentalPeriodParkingInputFields.RENTAL_START]?.value}
        />
        <DateFieldReadOnly
          extraClassNames='flex-grow'
          // name={RentalPeriodParkingInputFields.RENTAL_START}
          label={i18n.t(`contract.labels.${RentalPeriodParkingInputFields.RENTAL_END}`)}
          value={fields?.[RentalPeriodParkingInputFields.RENTAL_END]?.value}
        />
        <DateFieldReadOnly
          extraClassNames='flex-grow'
          // name={RentalPeriodParkingInputFields.RENTAL_START}
          label={i18n.t(`contract.labels.${RentalPeriodParkingInputFields.RENTAL_PAYMENT_START}`)}
          value={fields?.[RentalPeriodParkingInputFields.RENTAL_PAYMENT_START]?.value}
        />
        <NumberFieldReadOnly
          extraClassNames='flex-grow'
          label={i18n.t(`contract.labels.${RentalPeriodParkingInputFields.NOTICE_PERIOD}`)}
          // name={RentalPeriodParkingInputFields.NOTICE_PERIOD}
          required={true}
          value={(fields?.[RentalPeriodParkingInputFields.NOTICE_PERIOD]?.value || null)}
        />
      </div>
    </div>
  </div>
}
