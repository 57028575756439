export const MigrationBanner = ({ url }: any) => {
  return (
    <div className="bg-red-200 border-l-6 border-red-500 text-red-700 p-5 flex items-center gap-4 shadow-md rounded-lg shadow-md">
      <span className="text-3xl">⚠️</span>
      <span className="text-lg font-semibold">
        <strong>Viktig merknad:</strong> Plattformen vår har flyttet til et nytt sted. Vennligst oppdater bokmerkene dine og få
        tilgang til ny app via lenken nedenfor:
        <a
          href={url || 'https://leko.mittleieforhold.no'}
          className="underline font-semibold text-red-900 hover:text-red-700"
          style={{ paddingLeft: '5px' }}>
          Besøk den nye appen
        </a>
      </span>
    </div>
  )
}
