import { TextEditor } from 'src/components'
import { VisibleIf } from 'src/components_generic/visible-if'
import i18n from 'src/producers/languages/i18n'
import React from 'react'

enum LeaseConsistsOfShoppingMallFields {
  DESCRIPTION = 'introText'
}

export const LeaseConsistsOf: view = ({
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status
}) => {
  const isActiveContract = contractStatus === 'active'
  const displayReadOnlyView = isActiveContract || isLocked || true

  return <>
    <VisibleIf predicate={!displayReadOnlyView}>
      <LeaseConsistsOfShoppingMall/>
    </VisibleIf>
    <VisibleIf predicate={displayReadOnlyView}>
      <LeaseConsistsOfShoppingMallReadOnly/>
    </VisibleIf>
  </>
}

const LeaseConsistsOfShoppingMall: view = ({
  fields = observe.contract.originalv2.fields,
  setChanges = update.contract.changes.fields[param.name],
  resetToDefault = update.contract.changes.resetToDefault[param.name]
}) => {

  const GetDescriptionText = () => fields?.[LeaseConsistsOfShoppingMallFields.DESCRIPTION]?.value

  return <div>
    <h2>{i18n.t('contract.general.leaseConsistsOf')}</h2>
    {/*<span*/}
    {/*  className="cursor-pointer text-pink-600"*/}
    {/*  onClick={() => {*/}

    {/*    resetToDefault.set(true, {name: LeaseConsistsOfShoppingMallFields.DESCRIPTION})*/}
    {/*  }}*/}
    {/*>*/}
    {/*  {'text'}*/}
    {/*</span>*/}
    <div className="flex flex-col gap-4 sub-clause-card">
      <div className="flex flex-row items-center">
        <div className="w-full">
          <TextEditor
            name={LeaseConsistsOfShoppingMallFields.DESCRIPTION}
            value={GetDescriptionText()}
            onChange={(e: any) => {
              if (!e) return
              setChanges.set({ value: e }, { name: LeaseConsistsOfShoppingMallFields.DESCRIPTION })
            }}
          />
        </div>
      </div>
    </div>
  </div>
}

const LeaseConsistsOfShoppingMallReadOnly: view = ({
  fields = observe.contract.originalv2.fields
}) => {

  return <div>
    <h2>{i18n.t('contract.general.leaseConsistsOf')}</h2>

    <div className="flex flex-col gap-4 sub-clause-card">
      <div className="flex flex-row items-center">
        <div className="w-full">
          <TextEditor
            value={fields[LeaseConsistsOfShoppingMallFields.DESCRIPTION]?.value}
            readOnly={true}
          />
        </div>
      </div>
    </div>
  </div>
}
