import { DatePicker } from 'antd'

import i18n from 'src/producers/languages/i18n'
import { Modal } from 'src/components'
import { SVGs } from 'src/assets'
import { ContractType, UserRole } from 'src/types'
import { TextField } from '@c11/components'
import {
  CheckboxType2
} from 'src/pages/Contract/subviews/ContractForm/fields/CheckboxType2'
import { moveToSigning } from './moveToSigning'

export const MoveToSigningModal: view = ({
  show = observe.modal.moveToSigning,
  moveToSigning = update.contract.triggers.moveToSigning,
  userRole = get.user.data.role,
  contractType = get.contract.originalv2.state.contractType,
  _viewId,
  setExpiration = update.views[prop._viewId].expiration,
  expiration = observe.views[prop._viewId].expiration,
  setMessage = update.views[prop._viewId].message,
  message = observe.views[prop._viewId].message,
  updateSilentParty = update.views[prop._viewId].silentParty,
  silentParty = observe.views[prop._viewId].silentParty
}) => {
  const isManager = userRole.value() === UserRole.MANAGER
  if (!show || !isManager) return null
  contractType = contractType.value()
  if (silentParty === undefined) updateSilentParty.set(contractType !== ContractType.PARKING)
  return (
    <Modal
      title={`${i18n.t('contract_navbar.send_to_signing')}?`}
      okButtonText={i18n.t('contract_navbar.send_to_signing')}
      image={() => <SVGs.ModalGenerateContract/>}
      onCancelClick={() => {
        setMessage.remove()
        setExpiration.remove()
      }}
      onOkClick={() => {
        console.log('HEREEEEEE')
        moveToSigning.set({
          now: Date.now(),
          payload: { message: message ? message.replace(/(<([^>]+)>)/gi, '') : '', expiration, silentParty }
        })
      }
      }
      okButtonDisabled={!expiration}>
      <p key="1" className="mb-1">
        {i18n.t('modals.generate_contract_p1')}
      </p>
      <p key="2">{i18n.t('modals.generate_contract_p2')}</p>
      <div className="mt-4">
        {`${i18n.t('modals.generate_contract_add_deadline')}:`}
        <span className="text-pink-600 ml-2">{`(${i18n.t('general.required')})`}</span>
      </div>

      <DatePicker
        className="text-xl my-2"
        style={{ width: '50%', height: '45px' }}
        placeholder=""
        disabledDate={(selectedDate) => selectedDate.isBefore(new Date())}
        disabled={true}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        onChange={(moment: any) => setExpiration.set(new Date(moment.endOf('day')).getTime())}
      />
      <div translate={'no'} className="notranslate my-2">
        <TextField
          label={i18n.t('modals.send_message_to_tenant')}
          value={message}
          onChange={(e: any) => {
            if (e) {
              setMessage.set(e.target.value)
            }
          }}
        />
      </div>
      <CheckboxType2
        key="9"
        label={i18n.t('modals.notify_silent_party')}
        checked={!!silentParty}
        disabled={true}
        onClick={() => updateSilentParty.set(!silentParty)}
      />
    </Modal>
  )
}

MoveToSigningModal.producers([moveToSigning])
