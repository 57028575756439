import i18n from 'src/producers/languages/i18n'
import { Clause, SubClause } from 'src/components_generic/Clause'
import styles from '../../../../styles.module.css'
import { TextField, TextFieldReadOnly } from 'src/components_generic/TextField'
import { TextEditor, TextEditorReadOnly } from 'src/components'
import { AddendumFields } from './fields'
import { ContractTemplate } from 'src/pages/ContractV2/contract.types'
import { VisibleIf } from 'src/components_generic/visible-if'

export const AddendumDownpayment: view = ({
  addendum = observe.contract.originalv2,
  isReadOnlyView = observe.random.isReadOnlyView,
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status
}) => {
  const isActiveContract = contractStatus === 'active'
  const displayReadOnlyView = isActiveContract || isLocked || isReadOnlyView || true


  if (!addendum) return <></>
  if (!addendum.template) return <></>

  if (addendum.template !== ContractTemplate.DOWNPAYMENT) return <></>

  return <>
    <VisibleIf predicate={!displayReadOnlyView}>
      <AddendumDownpaymentComponent/>
    </VisibleIf>
    <VisibleIf predicate={displayReadOnlyView}>
      <AddendumDownpaymentReadOnlyComponent/>
    </VisibleIf>
  </>
}

const AddendumDownpaymentComponent: view = ({
  addendum = observe.contract.originalv2,
  triggerChanges = update.contract.changes.fields
}) => {

  const firstName = addendum.fields?.[AddendumFields.FIRST_NAME]?.value
  const lastName = addendum.fields?.[AddendumFields.LAST_NAME]?.value
  const phone = addendum.fields?.[AddendumFields.PHONE]?.value
  const email = addendum.fields?.[AddendumFields.EMAIL]?.value

  const contractTitle = addendum.fields?.[AddendumFields.CONTRACT_TITLE]?.value

  const landlordText = addendum.fields?.landlordName?.value
  const tenantText = addendum.fields?.tenantName?.value

  const backgroundAndReason = addendum.fields?.[AddendumFields.BACKGROUND_AND_PURPOSE]?.value
  const repaymentOfOutstanding = addendum.fields?.[AddendumFields.REPAYMENT_OF_OUTSTANDING]?.value
  const otherProvision = addendum.fields?.[AddendumFields.OTHER_PROVISIONS]?.value

  return <div className={`${styles.contract}`}>
    <div className={`${styles.innerContract} contract-centered`}>
      <Clause title={i18n.t('contract.general.tenant_contact')}>
        <SubClause>
          <div className={`${styles.row}`}>
            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.FIRST_NAME}`)}
              name={AddendumFields.FIRST_NAME}
              value={firstName}
              onChange={value => triggerChanges.merge({
                [AddendumFields.FIRST_NAME]: { value }
              })}
            />

            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.LAST_NAME}`)}
              name={AddendumFields.LAST_NAME}
              value={lastName}
              // placeholder={i18n.t(`contract.placeholders.${TenantInputFields.NAME}`)}
              onChange={value => triggerChanges.merge({
                [AddendumFields.LAST_NAME]: { value }
              })}
            />
          </div>

          <div className={`${styles.row}`}>
            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.PHONE}`)}
              name={AddendumFields.PHONE}
              value={phone}
              // placeholder={i18n.t(`contract.placeholders.${TenantInputFields.NAME}`)}
              onChange={value => triggerChanges.merge({
                [AddendumFields.PHONE]: { value }
              })}
            />

            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.EMAIL}`)}
              name={AddendumFields.EMAIL}
              required={true}
              value={email}
              // placeholder={i18n.t(`contract.placeholders.${TenantInputFields.NAME}`)}
              onChange={value => triggerChanges.merge({
                [AddendumFields.EMAIL]: { value }
              })}
            />
          </div>
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.title')}>
        <SubClause>
          <TextField
            name={AddendumFields.CONTRACT_TITLE}
            value={contractTitle}
            onChange={value => triggerChanges.merge({
              [AddendumFields.CONTRACT_TITLE]: { value }
            })}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.between')}>
        <SubClause title={i18n.t('contract.general.landlord')}>
          <div>{landlordText}</div>
        </SubClause>
        <SubClause title={i18n.t('contract.general.tenant')}>
          <div>{tenantText}</div>
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.background_and_reason')} index="1.">
        <SubClause>
          <TextEditor
            readOnly={false}
            value={backgroundAndReason}
            onChange={(e: any) => {
              triggerChanges.merge({
                [AddendumFields.BACKGROUND_AND_PURPOSE]: { value: e }
              })
            }}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.template.downpayment.repayment_of_outstanding')} index="2.">
        <SubClause>
          <TextEditor
            readOnly={false}
            value={repaymentOfOutstanding}
            onChange={(e: any) => {
              triggerChanges.merge({
                [AddendumFields.REPAYMENT_OF_OUTSTANDING]: { value: e }
              })
            }}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.other_provisions')} index="3.">
        <SubClause>
          <TextEditor
            readOnly={false}
            value={otherProvision}
            onChange={(e: any) => {
              triggerChanges.merge({
                [AddendumFields.OTHER_PROVISIONS]: { value: e }
              })
            }}
          />
        </SubClause>
      </Clause>
    </div>
  </div>
}

const AddendumDownpaymentReadOnlyComponent: view = ({
  addendum = get.contract.originalv2
}) => {

  const firstName = addendum.value().fields?.[AddendumFields.FIRST_NAME]?.value
  const lastName = addendum.value().fields?.[AddendumFields.LAST_NAME]?.value
  const phone = addendum.value().fields?.[AddendumFields.PHONE]?.value
  const email = addendum.value().fields?.[AddendumFields.EMAIL]?.value

  const contractTitle = addendum.value().fields?.[AddendumFields.CONTRACT_TITLE]?.value

  const landlordText = addendum.value().fields?.landlordName?.value
  const tenantText = addendum.value().fields?.tenantName?.value

  const backgroundAndReason = addendum.value().fields?.[AddendumFields.BACKGROUND_AND_PURPOSE]?.value
  const repaymentOfOutstanding = addendum.value().fields?.[AddendumFields.REPAYMENT_OF_OUTSTANDING]?.value
  const otherProvision = addendum.value().fields?.[AddendumFields.OTHER_PROVISIONS]?.value

  return <div className={`${styles.contract}`}>
    <div className={`${styles.innerContract} contract-centered`}>
      <Clause title={i18n.t('contract.general.tenant_contact')}>
        <SubClause>
          <div className={`${styles.row}`}>
            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.FIRST_NAME}`)}
              value={firstName}
            />
            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.LAST_NAME}`)}
              value={lastName}
            />
          </div>

          <div className={`${styles.row}`}>
            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.PHONE}`)}
              value={phone}
            />
            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.EMAIL}`)}
              value={email}
            />
          </div>
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.title')}>
        <SubClause>
          <TextFieldReadOnly
            value={contractTitle}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.between')}>
        <SubClause title={i18n.t('contract.general.landlord')}>
          <div>{landlordText}</div>
        </SubClause>
        <SubClause title={i18n.t('contract.general.tenant')}>
          <div>{tenantText}</div>
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.background_and_reason')} index="1.">
        <SubClause>
          <TextEditorReadOnly
            value={backgroundAndReason}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.template.downpayment.repayment_of_outstanding')} index="2.">
        <SubClause>
          <TextEditorReadOnly
            value={repaymentOfOutstanding}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.other_provisions')} index="3.">
        <SubClause>
          <TextEditorReadOnly
            value={otherProvision}
          />
        </SubClause>
      </Clause>
    </div>
  </div>
}
