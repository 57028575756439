import { Button } from '@c11/components'
import './SendToSigningButton.css'

import i18n from 'src/producers/languages/i18n'
import { DocumentCheckedIcon, PenIcon } from 'src/assets/svg'
import { TooltipWrapper } from 'src/components'
import { ContractSigningMethod, PartyType } from 'src/types'
import { validateData } from 'src/pages/ContractV2/producers'
import { VisibleIf } from 'src/components_generic/visible-if'
import { validateDataSelections } from 'src/pages/ContractV2/producers/validations/validateDataSelections'
import { AddendumTemplates } from 'src/pages/ContractV2/contract.types'

export const SendToSigningButton: view = ({
  hasInvalidFields = observe.contract.hasInvalidFields,
  isLoading = observe.contract.loading.sendToSigning,
  signers = observe.contract.originalv2.signers,
  state = get.contract.originalv2,

  updateHasInvalidFields = update.contract.hasInvalidFields,
  showModal = update.modal.moveToSigning,
  updateInvalidFields = update.contract.invalidFields[param.varName],
  removeAllInvalidFields = update.contract.invalidFields,

  updateTriggerNotifications = update.contract.triggerInvalidFieldsNotifications,
  errorNotification = update.errors[param.id],
}) => {
  signers = signers ? Object.values(signers) : []
  if (!state.value()) return null
  const isAddendum = AddendumTemplates.includes(state.value().template)
  const validate = (state) =>
    validateData(state.fields, state.template, updateInvalidFields, removeAllInvalidFields) &&
    (isAddendum || validateDataSelections(state.tenant, state.building, errorNotification, updateInvalidFields))
  const areSignersValid =
    !!signers.find((p: any) => p.partyType == PartyType.TENANT) && !!signers.find((p: any) => p.partyType == PartyType.LANDLORD)
  const isDigitalSigning = state.value().signing === ContractSigningMethod.DIGITAL
  const isDisabled = !areSignersValid
  let tooltipText
  // if (displayedVersion) tooltipText = i18n.t('button_generate_contract_oldVersion_tooltip')
  if (hasInvalidFields) tooltipText = i18n.t('contract_navbar.button_send_contract_tooltip_invalid_fields')
  if (!areSignersValid) tooltipText = i18n.t('parties_page.no_parties_warning')
  // if (hasChanges) tooltipText = i18n.t('contract_navbar.button_send_contract_tooltip_unpublished_changes')

  return (
    <>
      <Button
        className="send-signing-btn uppercase"
        variant="primary"
        isLoading={isLoading}
        isDisabled={isDisabled || true}
        onClick={() => {
          const isValid = validate(state.value())
          if (isValid) {
            updateHasInvalidFields.set(false)
            showModal.set(true)
          } else {
            updateHasInvalidFields.set(true)
            updateTriggerNotifications.set(true)
          }
        }}>
        {i18n.t('contract_navbar.send_to_signing')}
        {isDigitalSigning ? (
          <DocumentCheckedIcon className="ml-2" fill={`${isDisabled ? '#C9CACA' : '#000'}`} />
        ) : (
          <PenIcon className="ml-2" fill={`${isDisabled ? '#C9CACA' : '#000'}`} />
        )}
      </Button>
    </>
  )
}
