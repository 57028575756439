import {
  UserRole,
  ApplicationError,
  ErrorLevel,
  ErrorType,
  ContractStatus, ContractSigningMethod, ContractType
} from 'src/types'
import moment from 'moment'
import { db } from 'src/producers/firebase'
import { ContractFlow, ContractTemplate } from 'src/pages/ContractV2/contract.types'
import { router } from 'src/router'

export const createShoppingMallContract: producer = async ({
  trigger = observe.dashboard.triggers.createShoppingMallContract,
  removeTrigger = update.dashboard.triggers.createShoppingMallContract,
  defaultValues = get.defaultValues[param.template],
  // setCurrentPath = update.routes.currentPath,
  currentQuery = observe.routes.currentQuery,
  updateReroute = update.routes.triggers.reroute,
  setNewContractState = update.routes.newContractState,
  setState = update.contract.originalv2,
  user = get.user,
  error = update.errors[param.id],
}) => {
  return;
  user = user.value()
  const isManager = user?.data?.role === UserRole.MANAGER
  if (!trigger || !isManager) return

  try {
    let contractId: string = `Contract-${moment().format('DD-MM-YY_HH-mm-SSS')}`
    const defaultValuesForTemplate = defaultValues.value({ template: ContractTemplate.SHOPPING_MALL }) || {}
    const state = {
      fields: defaultValuesForTemplate,
      defaultValues: defaultValuesForTemplate,
      id: contractId,
      flow: ContractFlow.DIGITAL,
      template: ContractTemplate.SHOPPING_MALL,
      createdAt: new Date().toISOString(),
      status: ContractStatus.DRAFT,
      handler: user.uid,
      revision: 4,
      signing: ContractSigningMethod.DIGITAL,
      contractType: ContractType.DIGITAL
    }
    await db.collection('contracts').doc(contractId).set(state)
    setNewContractState.set(state)
    // console.log('here', state)
    // setState.set(state)
    // setCurrentPath.set(`/contract/${state.id}`)
    router.navigate(`/contract/${state.id}` + (currentQuery || ''))
    updateReroute.set(Date.now())
    console.log('digital contract created')

  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'createShoppingMallContract' }
    )
  } finally {
    removeTrigger.remove()
  }
}
