import i18n from "src/producers/languages/i18n";

import moment from "moment";
import { DatePicker } from "antd";
import styles from "../../../styles.module.css";
import { modifyExpiry } from "./modifyExpirary";
import { Spinner } from "@c11/components";
import dayjs from 'dayjs'

export const ExpiryDate: view = ({
  _viewId,
  value = observe.views[prop._viewId].trigger,
  expiration = observe.contract.originalv2.envelope.expiration,
  loading = observe.contract.flags.modifyExpiry,
  setExpiry = update.views[prop._viewId].trigger
}) => {
  value = value || expiration;

  const daysLeft = moment(value).diff(moment(Date.now()), "days");
  const expirationDaysText =
    daysLeft === 0
      ? i18n.t("summary_page.expiration_days_zero")
      : daysLeft < 0
      ? i18n.t("summary_page.expiration_days_none")
      : daysLeft === 1
      ? i18n.t("summary_page.expiration_days_one", { n: daysLeft })
      : i18n.t("summary_page.expiration_days_other", { n: daysLeft });

  return (
    <div className="border-r border-l border-gray-300 mx-4 px-4">
      <div className="flex justify-center">
        {loading ? <Spinner color="primary" size="small" /> : expirationDaysText}
      </div>
      <div
        className="cursor-pointer text-green-500"
        id="expirary-div"
        onClick={() => {
          const expiry = document.getElementById("expirary-div");
          const input = expiry.getElementsByTagName("input");
          input[0].click();
        }}>
        <DatePicker
          popupClassName={"datePicker-popup-hidden"}
          showToday={true}
          placement={"bottomLeft"}
          disabled={true}
          value={value ? dayjs(value) : null}
          className={`${styles.datePickerHidden}`}
          onChange={(value) => {
            setExpiry.set(value);
            // updateLoading.set(true);
          }}
        />

        {i18n.t("summary_page.modify_deadline")}
      </div>
    </div>
  );
};

ExpiryDate.producers([modifyExpiry]);
