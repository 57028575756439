import { Button } from '@c11/components'
import i18n from 'src/producers/languages/i18n'

export const NewAddendumButton: view = ({
  // createAddendum = observe.contract.triggers.createAddendum,
  // openModal = update.modal.newAddendum,
  addNewAddendumModal = observe.modal.addNewAddendumModal,
  updateAddNewAddendumModal = update.modal.addNewAddendumModal,
}) => (
  <Button
    className="uppercase mr-3"
    // isLoading={createAddendum}
    variant="secondary"
    isDisabled={addNewAddendumModal?.isOpen || true}
    onClick={() => {
      updateAddNewAddendumModal.set({
        isOpen: true,
      })
    }}>
    {i18n.t('addendum.new_addendum')}
  </Button>
)
