import "./NewAddendumModal.css";

import i18n from "src/producers/languages/i18n";
import { AgreementDigitalContractIcon } from "src/assets/svg";
import { AddendumType } from "src/types";
import { Modal, ThumbnailCard, ThumbnailCardBody, ThumbnailCardFooter, ThumbnailCardHeader } from "src/components";
import { Button } from "@c11/components.button";
import { SVGs } from "src/assets";
import styles from "../../styles.module.css";
import { ContractTemplate } from 'src/pages/ContractV2/contract.types'

export const NewAddendumModal: view = ({
  addNewAddendumModal = observe.modal.addNewAddendumModal,
  updateAddNewAddendumModal = update.modal.addNewAddendumModal,

  triggerCreateAddendum = update.contract.triggers.createAddendum,

  rentalPeriodPriceAddendumFlag = observe.featureFlags.rentalPeriodPriceAddendum.main,
  storageAddendumFlag = observe.featureFlags.storageAddendum.main,
  transportAddendumFlag = observe.featureFlags.transportAddendum.main,
}) => {
  if (!addNewAddendumModal?.isOpen) return null;

  return (
    <Modal
      title={i18n.t("contract.addendums.select_new_addendum")}
      width="auto"
      className={`${styles.multipleOptionsModal}`}
      cancelButtonHidden="true">
      <section className={`${styles.cardOptionsModal}`}>
        <div className={`${styles.modalOptionsRow}`}>
          <ThumbnailCard className={`${styles.option}`}>
            <ThumbnailCardHeader
              key="tch-1"
              className="font-robotoSlab flex-col-reverse"
              title={i18n.t("contract.addendums.cancellation_addendum")}>
              <AgreementDigitalContractIcon width={70} height={70} />
            </ThumbnailCardHeader>
            <ThumbnailCardBody key="tcb-1">
              <p className="text-gray-600">{i18n.t("contract.addendums.new_cancellation_description")}</p>
            </ThumbnailCardBody>
            <ThumbnailCardFooter key="tcf-1">
              <Button
                className={`${styles.modalSecondaryButton}`}
                isDisabled={true}
                onClick={() => {
                  triggerCreateAddendum.set({ template: ContractTemplate.CANCELLATION });
                  updateAddNewAddendumModal.set({isOpen: false});
                  // modal.remove();
                }}>
                <SVGs.RightArrowIcon className="mr-2" />
                {i18n.t("dashboard.agreement_option_button")}
              </Button>
            </ThumbnailCardFooter>
          </ThumbnailCard>
          <ThumbnailCard className={`${styles.option}`}>
            <ThumbnailCardHeader
              key="tch-1"
              className="font-robotoSlab flex-col-reverse"
              title={i18n.t("contract.addendums.downpayment_addendum")}>
              <AgreementDigitalContractIcon width={70} height={70} />
            </ThumbnailCardHeader>
            <ThumbnailCardBody key="tcb-1">
              <p className="text-gray-600">{i18n.t("contract.addendums.new_downpayment_description")}</p>
            </ThumbnailCardBody>
            <ThumbnailCardFooter key="tcf-1">
              <Button
                className={`${styles.modalSecondaryButton}`}
                isDisabled={true}
                onClick={() => {
                  triggerCreateAddendum.set({ template: ContractTemplate.DOWNPAYMENT });
                  updateAddNewAddendumModal.set({isOpen: false});
                  // modal.remove();
                }}>
                <SVGs.RightArrowIcon className="mr-2" />
                {i18n.t("dashboard.agreement_option_button")}
              </Button>
            </ThumbnailCardFooter>
          </ThumbnailCard>
          {rentalPeriodPriceAddendumFlag && (
            <ThumbnailCard className={`${styles.option}`}>
              <ThumbnailCardHeader
                key="tch-1"
                className="font-robotoSlab flex-col-reverse"
                title={i18n.t("contract.addendums.change_price_period_addendum")}>
                <AgreementDigitalContractIcon width={70} height={70} />
              </ThumbnailCardHeader>
              <ThumbnailCardBody key="tcb-1">
                <p className="text-gray-600">{i18n.t("contract.addendums.new_rental_period_price_description")}</p>
              </ThumbnailCardBody>
              <ThumbnailCardFooter key="tcf-1">
                <Button
                  className={`${styles.modalSecondaryButton}`}
                  isDisabled={true}
                  onClick={() => {
                    triggerCreateAddendum.set({ template: ContractTemplate.RENTAL_PERIOD_PRICE });
                    updateAddNewAddendumModal.set({isOpen: false});
                    // modal.remove();
                  }}>
                  <SVGs.RightArrowIcon className="mr-2" />
                  {i18n.t("dashboard.agreement_option_button")}
                </Button>
              </ThumbnailCardFooter>
            </ThumbnailCard>
          )}
          {storageAddendumFlag && (
            <ThumbnailCard className={`${styles.option}`}>
              <ThumbnailCardHeader
                key="tch-1"
                className="font-robotoSlab flex-col-reverse"
                title={i18n.t("contract.addendums.storage_addendum")}>
                <AgreementDigitalContractIcon width={70} height={70} />
              </ThumbnailCardHeader>
              <ThumbnailCardBody key="tcb-1">
                <p className="text-gray-600">{i18n.t("contract.addendums.new_storage_description")}</p>
              </ThumbnailCardBody>
              <ThumbnailCardFooter key="tcf-1">
                <Button
                  className={`${styles.modalSecondaryButton}`}
                  isDisabled={true}
                  onClick={() => {
                    triggerCreateAddendum.set({ template: ContractTemplate.STORAGE });
                    updateAddNewAddendumModal.set({isOpen: false});
                    // modal.remove();
                  }}>
                  <SVGs.RightArrowIcon className="mr-2" />
                  {i18n.t("dashboard.agreement_option_button")}
                </Button>
              </ThumbnailCardFooter>
            </ThumbnailCard>
          )}
          {transportAddendumFlag && (
            <ThumbnailCard className={`${styles.option}`}>
              <ThumbnailCardHeader
                key="tch-1"
                className="font-robotoSlab flex-col-reverse"
                title={i18n.t("contract.addendums.transport_addendum")}>
                <AgreementDigitalContractIcon width={70} height={70} />
              </ThumbnailCardHeader>
              <ThumbnailCardBody key="tcb-1">
                <p className="text-gray-600">{i18n.t("contract.addendums.new_transport_description")}</p>
              </ThumbnailCardBody>
              <ThumbnailCardFooter key="tcf-1">
                <Button
                  className={`${styles.modalSecondaryButton}`}
                  isDisabled={true}
                  onClick={() => {
                    triggerCreateAddendum.set({ template: ContractTemplate.TRANSPORT });
                    updateAddNewAddendumModal.set({isOpen: false});
                    // modal.remove();
                  }}>
                  <SVGs.RightArrowIcon className="mr-2" />
                  {i18n.t("dashboard.agreement_option_button")}
                </Button>
              </ThumbnailCardFooter>
            </ThumbnailCard>
          )}
        </div>
      </section>
    </Modal>
  );
};
