import { UserRole, ApplicationError, ErrorLevel, ErrorType, ContractStatus, ContractSigningMethod, ContractType } from 'src/types'
import moment from 'moment'
import { db } from 'src/producers/firebase'
import { router } from 'src/router'
import { ContractFlow, ContractTemplate } from 'src/pages/ContractV2/contract.types'

export const createPaperContract: producer = async ({
  trigger = observe.dashboard.triggers.createPaperContract,
  removeTrigger = update.dashboard.triggers.createPaperContract,
  // setCurrentPath = update.routes.currentPath, // TODO: update with new router
  currentQuery = observe.routes.currentQuery,
  updateReroute = update.routes.triggers.reroute,
  setNewContractState = update.routes.newContractState,
  user = get.user,
  isLoading = update.dashboard.triggers.createPaperContract,
  error = update.errors[param.id],
}) => {
  return;
  user = user.value()
  const isManager = user?.data?.role === UserRole.MANAGER
  if (!trigger || !isManager) return
  isLoading.set(true)

  try {
    let contractId: string = `Contract-${moment().format('DD-MM-YY_HH-mm-SSS')}`
    const state = {
      fields: {},
      defaultValues: {},
      id: contractId,
      flow: ContractFlow.PAPER,
      template: ContractTemplate.PAPER,
      createdAt: new Date().toISOString(),
      status: ContractStatus.DRAFT,
      handler: user.uid,
      revision: 4,
      signing: ContractSigningMethod.PAPER,
      contractType: ContractType.PAPER,
    }
    await db.collection('contracts').doc(contractId).set(state)
    setNewContractState.set(state)
    // console.log('here', state)
    // setState.set(state)
    // setCurrentPath.set(`/contract/${state.id}`)
    router.navigate(`/contract/${state.id}` + (currentQuery || ''))
    updateReroute.set(Date.now())
    console.log('paper contract created')

  } catch (e) {
    error.set(
      {
        message: e,
        level: ErrorLevel.BREAKING,
        type: ErrorType.SERVER,
      } as ApplicationError,
      { id: 'createAgreement' }
    )
  } finally {
    isLoading.set(false)
    removeTrigger.remove()
  }
}
