import i18n from 'src/producers/languages/i18n'
import { EditIcon, RecycleBinIcon } from 'src/assets/svg'
import { VisibleIf } from 'src/components_generic/visible-if'
import { Button } from '@c11/components.button'
import { Dropdown } from 'antd'
import { SVGs } from 'src/assets'
import { AddendumListElementExtraOption, AddendumListElementExtraOptionsFactory } from './AddendumListElementExtraOptions'

import styles from '../../styles.module.css'

export type AddendumListElement = {
  index: number
  name: string
  status: string

  isLocked?: boolean
  isLost?: boolean

  // onSelect?: () => void,
  onEdit?: () => void
  onDelete?: () => void
  onMarkAsLostToggled?: () => void
  onCopyLink?: () => void
}

export const AddendumListElement = ({
  index,
  name,
  status,
  isLost,
  isLocked,
  onEdit,
  onDelete,
  onMarkAsLostToggled,
  onCopyLink,
}: AddendumListElement) => {
  return (
    <div className={styles['cards-list']}>
      <div className={styles['cards-tab-list-item']}>
        <div className="flex-grow flex flex-row items-center ml-2">
          <div className="flex-grow">
            <div className={styles['addendum-list-name']}>{name}</div>
            <div className={styles['addendum-list-status']}>{status}</div>
          </div>

          <div className={styles['cards-info-row']}>
            <div className={styles['cards-actions-row']}>
              <div className="flex flex-row items-center gap-1">
                <VisibleIf predicate={!!isLost}>
                  <div className="contract-status-lost flex flex-row flex-grow items-center justify-center gap-1 mr-12">
                    <div className="flex-grow">
                      <SVGs.ContractStatusLost />
                    </div>
                    <div className="text-red-500">Lost</div>
                  </div>
                </VisibleIf>
                <VisibleIf predicate={!isLocked}>
                  <Button
                    variant="ghost"
                    className={styles['list-element-button'] + ' ' + styles['eye']}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()

                      if (!onEdit) return
                      onEdit()
                    }}>
                    <EditIcon className="text-gray-600" />
                  </Button>
                </VisibleIf>

                <VisibleIf predicate={!isLocked}>
                  <Button
                    variant="ghost"
                    className={styles['list-element-button'] + ' ' + styles['trash']}
                    isDisabled={true}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()

                      if (!onDelete) return
                      onDelete()
                    }}>
                    <RecycleBinIcon className="text-gray-600" />
                  </Button>
                </VisibleIf>

                <VisibleIf predicate={!isLocked}>
                  <Dropdown
                    menu={{
                      items: AddendumListElementExtraOptionsFactory({
                        isLostToggled: isLost,
                        onClick: (key) => {
                          if (key === AddendumListElementExtraOption.LOST) if (!!onMarkAsLostToggled) onMarkAsLostToggled()

                          if (key === AddendumListElementExtraOption.COPY_LINK) if (!!onCopyLink) onCopyLink()
                        },
                      }),
                    }}
                    placement="bottom"
                    trigger={['click']}>
                    <Button variant="ghost" className={styles['list-element-button']} isDisabled={true}>
                      <SVGs.ThreeDots />
                    </Button>
                  </Dropdown>
                </VisibleIf>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
