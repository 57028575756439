import i18n from "src/producers/languages/i18n";
import { PaperclipIcon } from "src/assets/svg";
import { Button } from "@c11/components.button";

const ACCEPTED_TYPES = ["application/pdf", "image/png", "image/jpeg", "image/jpg"]
const ACCEPTED_TYPES_STRING = ["PDF Document", "PNG Image", "JPEG or JPG Image"];

export const AddAttachmentButton: view = ({
  attachmentInput = observe.modal.attachmentInput,
  updateAttachmentInput = update.modal.attachmentInput,

  addNewAttachmentTrigger = update.contract.triggers.attachments.user.new,
  updateNewAttachment = update.contract.changes.attachments.user.new
}) => {
  const IsModalOpen = () => attachmentInput?.isOpen;

  return <Button
    variant="secondary"
    className="upload-attachment-btn flex uppercase gap-2 mr-3"
    isDisabled={true}
    onClick={() => {
      !IsModalOpen() && document.getElementById("upload-attachment-input")?.click();
      updateAttachmentInput.set({ isOpen: true })
      setTimeout(() => {
        updateAttachmentInput.set({ isOpen: false });

        const input = document.getElementById("upload-attachment-input") as HTMLInputElement;
        if (!input) return;
        input.value = "";
      }, 1000);
    }}>
    <PaperclipIcon fill="none" stroke="currentColor"/>
    <input
      id="upload-attachment-input"
      type="file"
      disabled={true}
      accept={ACCEPTED_TYPES.join(', ')}
      onChange={async (e) => {
        console.log("changing appendix", { ...e });
        const target = e.target?.files?.item(0);
        if (!target) {
          return;
        }

        if (ACCEPTED_TYPES.indexOf(target.type) < 0) {
          alert(`Cannot upload file due to not being of one of the following types: ${ACCEPTED_TYPES_STRING.join(', ')}`);
          return;
        }

        updateNewAttachment.set(target);
        addNewAttachmentTrigger.set(true);
      }}
    />
    <span>{i18n.t("attachments_page.button_attachments_upload")}</span>
  </Button>
};
