import { RadioButton, RadioButtonGroup, Spinner } from '@c11/components'
import { TextEditor, TextEditorReadOnly } from 'src/components'
import { NumberField, NumberFieldReadOnly } from 'src/components_generic/NumberField'
import { TextField } from 'src/components_generic/TextField'
import { VisibleIf } from 'src/components_generic/visible-if'
import i18n from 'src/producers/languages/i18n'
import { v4 } from 'uuid'
import styles from '../../../../../styles.module.css'
import { ReactNumberInput } from 'src/components_generic/ReactNumberInput'
import { VisibleIfInvalidField } from 'src/pages/ContractV2/components/VisibleIfInvalidField'

export const RentalObject: view = ({
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status
}) => {
  const isActiveContract = contractStatus === 'active'
  const displayReadOnlyView = isActiveContract || isLocked || true

  return <>
    <VisibleIf predicate={!displayReadOnlyView}>
      <RentalObjectParkingComponent/>
    </VisibleIf>
    <VisibleIf predicate={displayReadOnlyView}>
      <RentalObjectParkingComponentReadOnly/>
    </VisibleIf>
  </>
}

enum RentalObjectParkingInputFields {
  NUMBER_OF_PARKING_SPACES = 'numberOfParkingSpaces',
  NUMBER_OF_CHARGERS = 'numberOfChargers',
  PARKING_TYPE = 'parkingType',
  DESCRIPTION = 'rentalObject'
}

enum ParkingType {
  INSIDE_PARKING = 'inside-parking',
  OUTSIDE_PARKING = 'outside-parking'
}

const RentalObjectParkingComponent: view = ({
  fields = observe.contract.originalv2.fields,
  setChanges = update.contract.changes.fields[param.name],
  setMiscChanges = update.contract.changes.miscellaneous[param.name]
}) => {
  // if (!contract?.metadata?.readOnly) return null
  const GetParkingType = () => fields[RentalObjectParkingInputFields.PARKING_TYPE]?.value

  return <div>
    <h2>2. {i18n.t(`parking_contract.rental_object`)}</h2>
    <div className={`${styles.cardSection}`}>
      <RadioButtonGroup selectedValue={GetParkingType()} className="flex gap-7">
        <RadioButton
          key={v4()}
          value={ParkingType.INSIDE_PARKING}
          isDisabled={false}
          onClick={() => {
            setMiscChanges.set({
              value: ParkingType.INSIDE_PARKING
            }, {
              name: RentalObjectParkingInputFields.PARKING_TYPE
            })
          }}
        >
          {i18n.t(`parkingType.${ParkingType.INSIDE_PARKING}`)}
        </RadioButton>

        <RadioButton
          key={v4()}
          value={ParkingType.OUTSIDE_PARKING}
          isDisabled={false}
          onClick={() => {
            setMiscChanges.set({
              value: ParkingType.OUTSIDE_PARKING
            }, {
              name: RentalObjectParkingInputFields.PARKING_TYPE
            })
          }}
        >
          {i18n.t(`parkingType.${ParkingType.OUTSIDE_PARKING}`)}
        </RadioButton>

        <VisibleIfInvalidField name={RentalObjectParkingInputFields.PARKING_TYPE}>
          <div className="text-pink-600 text-xs mt-1">
            {i18n.t('errors.parking_type_not_selected')}
          </div>
        </VisibleIfInvalidField>

      </RadioButtonGroup>
      <div className={`${styles.row}`}>
        <ReactNumberInput
          decimalScale={0}
          label={i18n.t(`contract.labels.${RentalObjectParkingInputFields.NUMBER_OF_PARKING_SPACES}`)}
          name={RentalObjectParkingInputFields.NUMBER_OF_PARKING_SPACES}
          required={true}
          value={(fields?.[RentalObjectParkingInputFields.NUMBER_OF_PARKING_SPACES]?.value || null) as number}
          placeholder={i18n.t(`contract.placeholders.${RentalObjectParkingInputFields.NUMBER_OF_PARKING_SPACES}`)}
          onChange={value => setChanges.set({ value }, { name: RentalObjectParkingInputFields.NUMBER_OF_PARKING_SPACES })}
        />
        <ReactNumberInput
          decimalScale={0}
          label={i18n.t(`contract.labels.${RentalObjectParkingInputFields.NUMBER_OF_CHARGERS}`)}
          name={RentalObjectParkingInputFields.NUMBER_OF_CHARGERS}
          required={true}
          value={(fields?.[RentalObjectParkingInputFields.NUMBER_OF_CHARGERS]?.value || null) as number}
          placeholder={i18n.t(`contract.placeholders.${RentalObjectParkingInputFields.NUMBER_OF_CHARGERS}`)}
          onChange={value => setChanges.set({ value }, { name: RentalObjectParkingInputFields.NUMBER_OF_CHARGERS })}
        />
      </div>

      <div className="flex flex-row items-center justify-center gap-8">
        <div className="flex-grow">
          <TextEditor
            readOnly={false}
            value={fields[RentalObjectParkingInputFields.DESCRIPTION]?.value}
            onChange={(e: any) => {
              setChanges.set({
                value: e
              }, {
                name: RentalObjectParkingInputFields.DESCRIPTION
              })
            }}
          />
        </div>
      </div>
    </div>
  </div>
}

const RentalObjectParkingComponentReadOnly: view = ({
  contract = observe.contract.originalv2,
  fields = observe.contract.originalv2.fields
}) => {
  if (contract?.metadata?.readOnly) return null

  return <div>
    <h2>{i18n.t(`contract.general.rental_object`)}</h2>

    <div className={`${styles.cardSection}`}>

      <RadioButtonGroup
        selectedValue={fields[RentalObjectParkingInputFields.PARKING_TYPE]?.value}
        className="h-16 flex pt-7 gap-7"
      >
        <RadioButton
          key={v4()}
          value={ParkingType.INSIDE_PARKING}
          isDisabled={true}
        >
          {i18n.t(`parkingType.${ParkingType.INSIDE_PARKING}`)}
        </RadioButton>
        <RadioButton
          key={v4()}
          value={ParkingType.OUTSIDE_PARKING}
          isDisabled={true}
        >
          {i18n.t(`parkingType.${ParkingType.OUTSIDE_PARKING}`)}
        </RadioButton>
      </RadioButtonGroup>
      <div className={`${styles.row}`}>
        <NumberFieldReadOnly
          label={i18n.t(`contract.labels.${RentalObjectParkingInputFields.NUMBER_OF_PARKING_SPACES}`)}
          // name={RentalObjectParkingInputFields.NUMBER_OF_PARKING_SPACES}
          required={true}
          value={(fields?.[RentalObjectParkingInputFields.NUMBER_OF_PARKING_SPACES]?.value || null) as number}
        />
        <NumberFieldReadOnly
          label={i18n.t(`contract.labels.${RentalObjectParkingInputFields.NUMBER_OF_CHARGERS}`)}
          // name={RentalObjectParkingInputFields.NUMBER_OF_CHARGERS}
          required={true}
          value={(fields?.[RentalObjectParkingInputFields.NUMBER_OF_CHARGERS]?.value || null) as number}
        />
      </div>
      <TextEditorReadOnly
        readOnly={false}
        value={fields[RentalObjectParkingInputFields.DESCRIPTION]?.value}
      />
    </div>
  </div>
}
