import { TextEditor, TextEditorReadOnly } from "src/components";
import i18n from 'i18next'
import styles from "../../../styles.module.css"
import { VisibleIf } from "src/components_generic/visible-if";

enum SpecialProvisionsInputFields {
  SPECIAL_PROVISIONS_TEXT = "specialProvisions"
}

export const SpecialProvisions: view = ({
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status
}) => {
  const isActiveContract = contractStatus === "active";
  const displayReadOnlyView = isActiveContract || isLocked || true;

  return <>
    <VisibleIf predicate={!displayReadOnlyView}>
      <SpecialProvisionsComponent />
    </VisibleIf>
    <VisibleIf predicate={displayReadOnlyView}>
      <SpecialProvisionsComponentReadOnly />
    </VisibleIf>
  </>
};

const SpecialProvisionsComponent: view = ({
  fields = observe.contract.originalv2.fields,
  setChanges = update.contract.changes.fields[param.name]
}) => {
  // if (!contract?.metadata?.readOnly) return null
  const GetSpecialProvisionsText = () => fields?.[SpecialProvisionsInputFields.SPECIAL_PROVISIONS_TEXT]?.value;

  return <div>
    <h2>8. {i18n.t('contract.general.special_provisions')}</h2>

    <div className={`${styles.cardSection}`}>
      <div className="flex flex-row items-center">
        <div className="w-full flex-grow">
          <TextEditor
            readOnly={false}
            value={GetSpecialProvisionsText()}
            onChange={(e: any) => {
              setChanges.set({
                value: e
              }, {
                name: SpecialProvisionsInputFields.SPECIAL_PROVISIONS_TEXT
              })
            }}
          />
        </div>
      </div>
    </div>
  </div>
}

const SpecialProvisionsComponentReadOnly: view = ({
  contract = observe.contract.originalv2,
  fields = observe.contract.originalv2.fields
}) => {
  const GetSpecialProvisionsText = () => fields?.[SpecialProvisionsInputFields.SPECIAL_PROVISIONS_TEXT]?.value;

  return <div>
    <h2>8. {i18n.t('contract.general.special_provisions')}</h2>

    <div className={`${styles.cardSection}`}>
      <div className="flex flex-row items-center">
        <div className="w-full flex-grow">
          <TextEditorReadOnly
            readOnly={true}
            value={GetSpecialProvisionsText()}
          />
        </div>
      </div>
    </div>
  </div>
}
