import i18n from 'src/producers/languages/i18n'
import { Clause, SubClause } from 'src/components_generic/Clause'
import styles from '../../../../styles.module.css'
import { TextField, TextFieldReadOnly } from 'src/components_generic/TextField'
import { TextEditor, TextEditorReadOnly } from 'src/components'
import { NumberField, NumberFieldReadOnly } from 'src/components_generic/NumberField'
import { VisibleIf } from 'src/components_generic/visible-if'
import { AddendumFields } from './fields'
import { DateField, DateFieldReadOnly } from 'src/components_generic/DateField'
import { Dropdown } from 'antd'
import { RadioButton, RadioButtonGroup, Switch } from '@c11/components'
import { v4 } from 'uuid'
import { RentalPeriodExtensionType } from '../shoppingMall/clauses/RentalPeriod'
import { DropdownField } from 'src/components_generic/DropdownField'
import { RenewalPeriodDropdownItemsFactory } from '../shoppingMall/dropdowns/RenewalPeriodDropdown'
import { ReactNumberInput } from 'src/components_generic/ReactNumberInput'
import { PaymentProvisionType } from '../shoppingMall/clauses/RentalCosts'
import { VATType } from '../shoppingMall/clauses/VAT'
import { ContractTemplate } from 'src/pages/ContractV2/contract.types'
import FormatNumber from 'format-number'
import { VisibleIfInvalidField } from 'src/pages/ContractV2/components/VisibleIfInvalidField'

export const AddendumRentalAgreementStorageUnit: view = ({
  addendum = observe.contract.originalv2,
  isReadOnlyView = observe.random.isReadOnlyView,
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status
}) => {
  const isActiveContract = contractStatus === 'active'
  const displayReadOnlyView = isActiveContract || isLocked || isReadOnlyView || true

  if (!addendum) return <></>
  if (!addendum.template) return <></>

  if (addendum.template !== ContractTemplate.STORAGE) return <></>

  return <>
    <VisibleIf predicate={!displayReadOnlyView}>
      <AddendumRentalAgreementStorageUnitComponent/>
    </VisibleIf>
    <VisibleIf predicate={displayReadOnlyView}>
      <AddendumRentalAgreementStorageUnitReadOnlyComponent/>
    </VisibleIf>
  </>
}

const AreaFormatter = FormatNumber({
  suffix: 'm sq',
  integerSeparator: '.',
  decimal: ',',
  round: 2
})

const CurrencyFormatter = FormatNumber({
  integerSeparator: '.',
  decimal: ',',
  round: 2
})

const AddendumRentalAgreementStorageUnitComponent: view = ({
  fields = observe.contract.originalv2.fields,
  // contractTitle = get.contract.originalv2.title.value(),
  triggerChanges = update.contract.changes.fields,
  setChange = update.contract.changes.fields[param.field].value,
  miscChanges = update.contract.changes.miscellaneous[param.field].value
}) => {

  const firstName = fields?.[AddendumFields.FIRST_NAME]?.value
  const lastName = fields?.[AddendumFields.LAST_NAME]?.value
  const phone = fields?.[AddendumFields.PHONE]?.value
  const email = fields?.[AddendumFields.EMAIL]?.value

  const contractTitle = fields?.[AddendumFields.CONTRACT_TITLE]?.value

  const landlordText = fields?.[AddendumFields.LANDLORD_NAME]?.value
  const tenantText = fields?.[AddendumFields.TENANT_NAME]?.value

  const backgroundAndReason = fields?.[AddendumFields.BACKGROUND_AND_PURPOSE]?.value

  const shoppingMall = fields?.[AddendumFields.PROPERTY_SHOPPING_MALL]?.value
  const propertyAddress = fields?.[AddendumFields.PROPERTY_ADDRESS]?.value
  const propertyMunicipality = fields?.[AddendumFields.PROPERTY_MUNICIPALITY]?.value
  const propertyParcelNumber = fields?.[AddendumFields.PROPERTY_PARCEL_NUMBER]?.value
  const propertyLocalNumber = fields?.[AddendumFields.PROPERTY_LOCAL_NO]?.value
  const propertyLevel = fields?.[AddendumFields.PROPERTY_LEVEL]?.value
  const propertyApproxExclusiveArea = fields?.[AddendumFields.PROPERTY_APPROX_EXCL_AREA]?.value
  const propertyCommonArea = fields?.[AddendumFields.PROPERTY_COMMON_AREA]?.value
  const propertyTotalArea = fields?.[AddendumFields.PROPERTY_TOTAL_AREA]
  const propertyConditionAndUse = fields?.[AddendumFields.PROPERTY_CONDITION_AND_USE]?.value

  const rentalStart = fields?.[AddendumFields.RENTAL_START]?.value
  const rentalEnd = fields?.[AddendumFields.RENTAL_END]?.value
  const rentalPaymentStart = fields?.[AddendumFields.RENTAL_PAYMENT_START]?.value
  const hasExtensionOptions = fields?.[AddendumFields.RENTAL_HAS_EXTENSION_OPTIONS]?.value
  const extensionType = fields?.[AddendumFields.RENTAL_EXTENSION_TYPE]?.value
  const extensionYears = fields?.[AddendumFields.RENTAL_EXTENSION_NUMBER_OF_YEARS]?.value

  const storageRent = fields?.[AddendumFields.STORAGE_RENT]?.value
  const sharedCosts = fields?.[AddendumFields.SHARED_COSTS]?.value
  const provisionType = fields?.[AddendumFields.PROVISION_TYPE]?.value
  const firstTimeRegulation = fields?.[AddendumFields.FIRST_TIME_REGULATION]?.value
  const basicIndex = fields?.[AddendumFields.BASIC_INDEX]?.value

  const vatStatus = fields?.[AddendumFields.BUSINESS_VAT_STATUS]?.value
  const vatPercentage = fields?.[AddendumFields.BUSINESS_VAT_PERCENTAGE]?.value

  const derogation = fields?.[AddendumFields.DEROGATION]?.value
  const otherProvisions = fields?.[AddendumFields.OTHER_PROVISIONS]?.value

  return <div className={`${styles.contract}`}>
    <div className={`${styles.innerContract} contract-centered`}>
      <Clause title={i18n.t('contract.general.tenant')}>
        <SubClause>
          <div className={`${styles.row}`}>
            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.FIRST_NAME}`)}
              name={AddendumFields.FIRST_NAME}
              value={firstName}
              // placeholder={i18n.t(`contract.placeholders.${TenantInputFields.NAME}`)}
              onChange={value => setChange.set(value, { field: AddendumFields.FIRST_NAME })}
            />

            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.LAST_NAME}`)}
              name={AddendumFields.LAST_NAME}
              value={lastName}
              // placeholder={i18n.t(`contract.placeholders.${TenantInputFields.NAME}`)}
              onChange={value => setChange.set(value, { field: AddendumFields.LAST_NAME })}
            />
          </div>

          <div className={`${styles.row}`}>
            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.PHONE}`)}
              name={AddendumFields.PHONE}
              value={phone}
              // placeholder={i18n.t(`contract.placeholders.${TenantInputFields.NAME}`)}
              onChange={value => setChange.set(value, { field: AddendumFields.PHONE })}
            />

            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.EMAIL}`)}
              name={AddendumFields.EMAIL}
              required={true}
              value={email}
              // placeholder={i18n.t(`contract.placeholders.${TenantInputFields.NAME}`)}
              onChange={value => setChange.set(value, { field: AddendumFields.EMAIL })}
            />
          </div>
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.title')}>
        <SubClause>
          <TextField
            name={AddendumFields.CONTRACT_TITLE}
            value={contractTitle}
            onChange={value => setChange.set(value, { field: AddendumFields.CONTRACT_TITLE })}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.general.parties')}>
        <SubClause title={i18n.t('contract.general.landlord')}>
          <div>{landlordText}</div>
        </SubClause>
        <SubClause title={i18n.t('contract.general.tenant')}>
          <div>{tenantText}</div>
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.background_and_reason')} index="1.">
        <SubClause>
          <TextEditor
            readOnly={false}
            value={backgroundAndReason}
            onChange={value => setChange.set(value, { field: AddendumFields.BACKGROUND_AND_PURPOSE })}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.general.rental_property')} index="2.">
        <SubClause title={i18n.t('contract.general.rental_property')} index="2.1">
          <div className={`${styles.row}`}>
            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.PROPERTY_SHOPPING_MALL}`)}
              name={AddendumFields.PROPERTY_SHOPPING_MALL}
              required={true}
              value={shoppingMall}
              // placeholder={i18n.t(`contract.placeholders.${AddendumRentalAgreementStorageUnitInputFields.SHOPPING_MALL}`)}
              onChange={value => setChange.set(value, { field: AddendumFields.PROPERTY_SHOPPING_MALL })}
            />

            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.PROPERTY_ADDRESS}`)}
              name={AddendumFields.PROPERTY_ADDRESS}
              required={true}
              value={propertyAddress}
              // placeholder={i18n.t(`contract.placeholders.${StorageUnitInputFields.ADDRESS}`)}
              onChange={value => setChange.set(value, { field: AddendumFields.PROPERTY_ADDRESS })}
            />
          </div>

          <div className={`${styles.row}`}>
            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.PROPERTY_MUNICIPALITY}`)}
              name={AddendumFields.PROPERTY_MUNICIPALITY}
              required={true}
              value={propertyMunicipality}
              // value={(fields[StorageUnitInputFields.MUNICIPALITY]?.value || null) as string}
              // placeholder={i18n.t(`contract.placeholders.${StorageUnitInputFields.MUNICIPALITY}`)}
              onChange={value => setChange.set(value, { field: AddendumFields.PROPERTY_MUNICIPALITY })}
            />

            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.PROPERTY_PARCEL_NUMBER}`)}
              name={AddendumFields.PROPERTY_PARCEL_NUMBER}
              required={true}
              value={propertyParcelNumber}
              // value={(fields[AddendumRentalAgreementStorageUnitInputFields.PARCEL_NUMBER]?.value || null) as string}
              // placeholder={i18n.t(`contract.placeholders.${AddendumRentalAgreementStorageUnitInputFields.PARCEL_NUMBER}`)}
              onChange={value => setChange.set(value, { field: AddendumFields.PROPERTY_PARCEL_NUMBER })}
            />
          </div>

          <div className={`${styles.row}`}>
            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.PROPERTY_LOCAL_NO}`)}
              name={AddendumFields.PROPERTY_LOCAL_NO}
              required={true}
              value={propertyLocalNumber}
              // value={(fields[RentalPropertyInputFields.LOCAL_NUMBER]?.value || null) as number}
              // placeholder={i18n.t(`contract.placeholders.${RentalPropertyInputFields.LOCAL_NUMBER}`)}
              onChange={value => setChange.set(value, { field: AddendumFields.PROPERTY_LOCAL_NO })}
            />

            <TextField
              label={i18n.t(`contract.labels.${AddendumFields.PROPERTY_LEVEL}`)}
              name={AddendumFields.PROPERTY_LEVEL}
              required={true}
              value={propertyLevel}
              // value={(fields[RentalPropertyInputFields.LEVEL]?.value || null) as number}
              // placeholder={i18n.t(`contract.placeholders.${RentalPropertyInputFields.LEVEL}`)}
              onChange={value => setChange.set(value, { field: AddendumFields.PROPERTY_LEVEL })}
              // onChange={value => triggerChanges.merge({
              //   [AddendumFields.PROPERTY_LEVEL]: { value }
              // })}
            />

            <ReactNumberInput
              label={i18n.t(`contract.labels.${AddendumFields.PROPERTY_APPROX_EXCL_AREA}`)}
              name={AddendumFields.PROPERTY_APPROX_EXCL_AREA}
              required={true}
              value={propertyApproxExclusiveArea}
              onChange={value => setChange.set(value, { field: AddendumFields.PROPERTY_APPROX_EXCL_AREA })}
            />

            <ReactNumberInput
              label={i18n.t(`contract.labels.${AddendumFields.PROPERTY_COMMON_AREA}`)}
              name={AddendumFields.PROPERTY_COMMON_AREA}
              required={true}
              value={propertyCommonArea}
              // value={(fields[RentalPropertyInputFields.COMMON_AREA]?.value || null) as number}
              // placeholder={i18n.t(`contract.placeholders.${RentalPropertyInputFields.COMMON_AREA}`)}
              onChange={value => setChange.set(value, { field: AddendumFields.PROPERTY_COMMON_AREA })}
              // onChange={value => triggerChanges.merge({ value }, {
              //   name: AddendumFields.PROPERTY_COMMON_AREA
              // })}
            />
          </div>

          <VisibleIf predicate={!propertyTotalArea?.value}>
            <div className="mt-3 font-bold text-right">
              {i18n.t('contract.general.total_area_fill_fields')}
            </div>
          </VisibleIf>

          <VisibleIf predicate={!!propertyTotalArea?.value}>
            <div className="flex flex-row flex-grow items-center justify-end mt-6">
              <div>
                <div className="text-right mr-6 mt-1">{i18n.t(`contract.labels.${AddendumFields.PROPERTY_TOTAL_AREA}`)}</div>
              </div>
              <TextFieldReadOnly
                extraClassNames={`${styles.totalAreaField}`}
                // value={AreaFormatter(fields.propertyTotalArea?.value)}
                value={AreaFormatter(propertyTotalArea?.value)}
              />
            </div>
          </VisibleIf>
        </SubClause>
        <SubClause title={i18n.t('contract.addendums.template.storage.propertyConditionAndUse')} index="2.2">
          <TextEditor
            readOnly={false}
            value={propertyConditionAndUse}
            onChange={(e: any) => {
              triggerChanges.merge({
                [AddendumFields.PROPERTY_CONDITION_AND_USE]: { value: e }
              })
            }}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.general.rental_period')} index="3.">
        <SubClause>
          <div className={`${styles.row}`}>
            <DateField
              extraClassNames="flex-grow"
              label={i18n.t(`contract.labels.${AddendumFields.RENTAL_START}`)}
              name={AddendumFields.RENTAL_START}
              value={rentalStart}
              onChange={value => {
                miscChanges.set(value, { field: AddendumFields.RENTAL_START })
              }}
            />
            <DateField
              extraClassNames="flex-grow"
              label={i18n.t(`contract.labels.${AddendumFields.RENTAL_END}`)}
              name={AddendumFields.RENTAL_END}
              value={rentalEnd}
              onChange={value => {
                console.log(`Date changed`, value)
                miscChanges.set(value, { field: AddendumFields.RENTAL_END })
              }}
            />
            <DateField
              extraClassNames="flex-grow"
              label={i18n.t(`contract.labels.${AddendumFields.RENTAL_PAYMENT_START}`)}
              name={AddendumFields.RENTAL_PAYMENT_START}
              value={rentalPaymentStart}
              onChange={(value) => {
                miscChanges.set(value, { field: AddendumFields.RENTAL_PAYMENT_START })
              }}
            />
          </div>
          <div className={`${styles.toggleHeader}`}>
            <div className="sub-clause-header">{i18n.t('contract.general.rental_period_potential_options')}</div>
            <Switch
              // key={AddendumFields.RENTAL_HAS_EXTENSION_OPTIONS}
              className={`secondary-switch-${!!hasExtensionOptions ? 'checked' : 'unchecked'}`}
              isChecked={!!hasExtensionOptions}
              isDisabled={false}
              onClick={_ => {
                miscChanges.set(!hasExtensionOptions, { field: AddendumFields.RENTAL_HAS_EXTENSION_OPTIONS })
              }}
            />
          </div>

          <VisibleIf predicate={!!hasExtensionOptions}>
            {/* <div className="flex flex-row items-center">
          <div>{i18n.t(`contract.labels.${AddendumFields.RENTAL_HAS_EXTENSION_OPTIONS}`)}</div>
        </div> */}

            <div className="flex flex-col gap-4">
              <RadioButtonGroup selectedValue={extensionType} className="flex w-full gap-8">
                <RadioButton
                  key={v4()}
                  value={RentalPeriodExtensionType.EXTENSION}
                  isDisabled={false}
                  onClick={() => miscChanges.set(RentalPeriodExtensionType.EXTENSION, { field: AddendumFields.RENTAL_EXTENSION_TYPE })}
                >
                  {i18n.t(`contract.fields.${AddendumFields.RENTAL_EXTENSION_TYPE}.${RentalPeriodExtensionType.EXTENSION}`)}
                </RadioButton>
                <RadioButton
                  key={v4()}
                  value={RentalPeriodExtensionType.RENEWAL}
                  isDisabled={false}
                  onClick={() => miscChanges.set(RentalPeriodExtensionType.RENEWAL, { field: AddendumFields.RENTAL_EXTENSION_TYPE })}
                >
                  {i18n.t(`contract.fields.${AddendumFields.RENTAL_EXTENSION_TYPE}.${RentalPeriodExtensionType.RENEWAL}`)}
                </RadioButton>

                <VisibleIfInvalidField name={AddendumFields.RENTAL_EXTENSION_TYPE}>
                  <div className="text-pink-600 text-xs mt-1">
                    {i18n.t('errors.rental_period_extension_type_not_selected')}
                  </div>
                </VisibleIfInvalidField>
              </RadioButtonGroup>

              <div className="w-1/2">
                <Dropdown
                  menu={{
                    items: RenewalPeriodDropdownItemsFactory({
                      onClick: key => {
                        miscChanges.set(key, { field: AddendumFields.RENTAL_EXTENSION_NUMBER_OF_YEARS })
                      }
                    })
                  }}
                  placement="bottom"
                  trigger={['click']}
                >
                  <div className="w-full">
                    <DropdownField
                      label={i18n.t(`contract.labels.${AddendumFields.RENTAL_EXTENSION_NUMBER_OF_YEARS}`)}
                      value={extensionYears}
                    />
                  </div>
                </Dropdown>
              </div>
            </div>
            <VisibleIf predicate={extensionType === RentalPeriodExtensionType.EXTENSION}>
              <p>
                {
                  i18n.t('contract.general.rental_period_extension_description', {
                    years: extensionYears
                  })
                }
              </p>
            </VisibleIf>
            <VisibleIf predicate={extensionType === RentalPeriodExtensionType.RENEWAL}>
              <p>
                {
                  i18n.t('contract.general.rental_period_renewal_description', {
                    years: extensionYears
                  })
                }
              </p>
            </VisibleIf>
          </VisibleIf>
        </SubClause>
      </Clause>

      {/*<Clause title="Storage rent, common costs and index adjustment" index="4.">*/}
      <Clause title={i18n.t('contract.addendums.template.storage.rental_costs')} index="4.">
        <SubClause title={i18n.t('contract.general.storage_rent')} index="4.1">
          <div className="flex-grow w-1/2">
            <div className="w-full mt-3">{i18n.t(`contract.general.storage_rent_description`)}</div>
          </div>

          <ReactNumberInput
            label={i18n.t(`contract.labels.${AddendumFields.STORAGE_RENT}`)}
            extraClassNames="w-1/2"
            name={AddendumFields.STORAGE_RENT}
            value={parseFloat(storageRent)}
            // placeholder={i18n.t(`contract.placeholders.${AddendumFields.STORAGE_RENT}`)}
            onChange={value => triggerChanges.merge({
              [AddendumFields.STORAGE_RENT]: { value }
            })}
          />
        </SubClause>

        <SubClause title={i18n.t('contract.general.shared_costs')} index="4.2">
          <div>
            {i18n.t(`contract.general.shared_costs_description`)}
          </div>

          <div className={`${styles.row}`}>
            <ReactNumberInput
              label={i18n.t(`contract.labels.${AddendumFields.SHARED_COSTS}`)}
              name={AddendumFields.SHARED_COSTS}
              required={true}
              value={sharedCosts}
              placeholder={i18n.t(`contract.placeholders.${AddendumFields.SHARED_COSTS}`)}
              onChange={value => triggerChanges.merge({
                [AddendumFields.SHARED_COSTS]: { value }
              })}
            />
          </div>
        </SubClause>

        <SubClause title={i18n.t('contract.general.index_adjustment')} index="4.3">
          <div className={`${styles.row}`}>
            <DateField
              extraClassNames="flex-grow w-1/2"
              label={i18n.t(`contract.labels.${AddendumFields.FIRST_TIME_REGULATION}`)}
              name={AddendumFields.FIRST_TIME_REGULATION}
              value={firstTimeRegulation}
              onChange={value => {
                miscChanges.set(value, { field: AddendumFields.FIRST_TIME_REGULATION })
              }}
            />
            <DateField
              extraClassNames="flex-grow w-1/2"
              label={i18n.t(`contract.labels.${AddendumFields.BASIC_INDEX}`)}
              name={AddendumFields.BASIC_INDEX}
              value={basicIndex}
              onChange={value => {
                miscChanges.set(value, { field: AddendumFields.BASIC_INDEX })
              }}
            />
          </div>
        </SubClause>

        <SubClause title={i18n.t('contract.general.payment_provision')} index="4.4">
          <RadioButtonGroup selectedValue={provisionType} className="flex flex-row gap-8">
            <RadioButton
              key={v4()}
              value={PaymentProvisionType.QUARTERLY_IN_ADVANCE}
              isDisabled={false}
              onClick={() => {
                miscChanges.set(PaymentProvisionType.QUARTERLY_IN_ADVANCE, { field: AddendumFields.PROVISION_TYPE })
              }}
            >
              {i18n.t(`contract.fields.${AddendumFields.PROVISION_TYPE}.${PaymentProvisionType.QUARTERLY_IN_ADVANCE}`)}
            </RadioButton>
            <RadioButton
              key={v4()}
              value={PaymentProvisionType.MONTHLY_IN_ADVANCE}
              isDisabled={false}
              onClick={() => {
                miscChanges.set(PaymentProvisionType.MONTHLY_IN_ADVANCE, { field: AddendumFields.PROVISION_TYPE })
              }}
            >
              {i18n.t(`contract.fields.${AddendumFields.PROVISION_TYPE}.${PaymentProvisionType.MONTHLY_IN_ADVANCE}`)}
            </RadioButton>

            <VisibleIfInvalidField name={AddendumFields.PROVISION_TYPE}>
              <div className="text-pink-600 text-xs mt-1">
                {i18n.t('errors.common_costs_not_selected')}
              </div>
            </VisibleIfInvalidField>
          </RadioButtonGroup>
        </SubClause>
      </Clause>

      {/*<Clause title="Merverdiavgift" index="5.">*/}
      <Clause title={i18n.t('contract.general.vat')} index="5.">
        <div className="flex flex-col items-center justify-center">
          <VisibleIfInvalidField name={AddendumFields.BUSINESS_VAT_STATUS}>
            <div className="self-start text-pink-600 text-xs mb-4 ml-8">
              {i18n.t('errors.vat_exemption_not_selected')}
            </div>
          </VisibleIfInvalidField>

          <RadioButtonGroup selectedValue={vatStatus} className="flex flex-col gap-4">
            <RadioButton
              key={v4()}
              value={VATType.EXCLUSIVELY_WITHIN_VAT}
              isDisabled={false}
              onClick={() => {
                miscChanges.set(VATType.EXCLUSIVELY_WITHIN_VAT, { field: AddendumFields.BUSINESS_VAT_STATUS })
              }}
            >
              {i18n.t(`contract.fields.${AddendumFields.BUSINESS_VAT_STATUS}.exclusively_within_vat`)}

            </RadioButton>
            <RadioButton
              key={v4()}
              value={VATType.EXCLUSIVELY_OUTSIDE_VAT}
              isDisabled={false}
              onClick={() => {
                miscChanges.set(VATType.EXCLUSIVELY_OUTSIDE_VAT, { field: AddendumFields.BUSINESS_VAT_STATUS })
              }}
            >
              {i18n.t(`contract.fields.${AddendumFields.BUSINESS_VAT_STATUS}.exclusively_outside_vat`)}
            </RadioButton>
            <RadioButton
              className="items-center justify-center"
              key={v4()}
              value={VATType.PARTIALLY_WITHIN_VAT}
              isDisabled={false}
              onClick={() => {
                miscChanges.set(VATType.PARTIALLY_WITHIN_VAT, { field: AddendumFields.BUSINESS_VAT_STATUS })
              }}
            >
              <div className="flex items-center gap-6">
                <VisibleIf predicate={vatStatus === VATType.PARTIALLY_WITHIN_VAT}>
                  <div className="w-1/2 mb-1">
                    <ReactNumberInput
                      // label={i18n.t(`contract.labels.${AddendumFields.BUSINESS_VAT_PERCENTAGE}`)}
                      name={AddendumFields.BUSINESS_VAT_PERCENTAGE}
                      value={vatPercentage}
                      // required={true}
                      onChange={(e) => {
                        triggerChanges.merge({
                          [AddendumFields.BUSINESS_VAT_PERCENTAGE]: { value: e }
                        })
                      }}
                    />
                  </div>
                </VisibleIf>
                {i18n.t(`contract.fields.${AddendumFields.BUSINESS_VAT_STATUS}.partially_within_vat`)}
              </div>
            </RadioButton>
          </RadioButtonGroup>
        </div>
      </Clause>

      <Clause title={i18n.t('contract.general.derogation')} index="6.">
        <SubClause>
          <TextEditor
            readOnly={false}
            value={derogation}
            onChange={(e: any) => {
              triggerChanges.merge({
                [AddendumFields.DEROGATION]: { value: e }
              })
            }}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.other_provisions')} index="7.">
        <SubClause>
          <TextEditor
            readOnly={false}
            value={otherProvisions}
            onChange={(e: any) => {
              triggerChanges.merge({
                [AddendumFields.OTHER_PROVISIONS]: { value: e }
              })
            }}
          />
        </SubClause>
      </Clause>
    </div>
  </div>
}

const AddendumRentalAgreementStorageUnitReadOnlyComponent: view = ({
  fields = get.contract.originalv2.fields.value(),
  // contractTitle = get.contract.originalv2.title.value()
}) => {

  const firstName = fields?.[AddendumFields.FIRST_NAME]?.value
  const lastName = fields?.[AddendumFields.LAST_NAME]?.value
  const phone = fields?.[AddendumFields.PHONE]?.value
  const email = fields?.[AddendumFields.EMAIL]?.value

  const contractTitle = fields?.[AddendumFields.CONTRACT_TITLE]?.value

  const landlordText = fields?.[AddendumFields.LANDLORD_NAME]?.value
  const tenantText = fields?.[AddendumFields.TENANT_NAME]?.value

  const backgroundAndReason = fields?.[AddendumFields.BACKGROUND_AND_PURPOSE]?.value

  const shoppingMall = fields?.[AddendumFields.PROPERTY_SHOPPING_MALL]?.value
  const propertyAddress = fields?.[AddendumFields.PROPERTY_ADDRESS]?.value
  const propertyMunicipality = fields?.[AddendumFields.PROPERTY_MUNICIPALITY]?.value
  const propertyParcelNumber = fields?.[AddendumFields.PROPERTY_PARCEL_NUMBER]?.value
  const propertyLocalNumber = fields?.[AddendumFields.PROPERTY_LOCAL_NO]?.value
  const propertyLevel = fields?.[AddendumFields.PROPERTY_LEVEL]?.value
  const propertyApproxExclusiveArea = fields?.[AddendumFields.PROPERTY_APPROX_EXCL_AREA]?.value
  const propertyCommonArea = fields?.[AddendumFields.PROPERTY_COMMON_AREA]?.value
  const propertyTotalArea = fields?.[AddendumFields.PROPERTY_TOTAL_AREA]
  const propertyConditionAndUse = fields?.[AddendumFields.PROPERTY_CONDITION_AND_USE]?.value

  const rentalStart = fields?.[AddendumFields.RENTAL_START]?.value
  const rentalEnd = fields?.[AddendumFields.RENTAL_END]?.value
  const rentalPaymentStart = fields?.[AddendumFields.RENTAL_PAYMENT_START]?.value
  const hasExtensionOptions = fields?.[AddendumFields.RENTAL_HAS_EXTENSION_OPTIONS]?.value
  const extensionType = fields?.[AddendumFields.RENTAL_EXTENSION_TYPE]?.value
  const extensionYears = fields?.[AddendumFields.RENTAL_EXTENSION_NUMBER_OF_YEARS]?.value

  const storageRent = fields?.[AddendumFields.STORAGE_RENT]?.value
  const sharedCosts = fields?.[AddendumFields.SHARED_COSTS]?.value
  const firstTimeRegulation = fields?.[AddendumFields.FIRST_TIME_REGULATION]?.value
  const basicIndex = fields?.[AddendumFields.BASIC_INDEX]?.value
  const provisionType = fields?.[AddendumFields.PROVISION_TYPE]?.value

  const vatStatus = fields?.[AddendumFields.BUSINESS_VAT_STATUS]?.value
  const vatPercentage = fields?.[AddendumFields.BUSINESS_VAT_PERCENTAGE]?.value

  const derogation = fields?.[AddendumFields.DEROGATION]?.value
  const otherProvisions = fields?.[AddendumFields.OTHER_PROVISIONS]?.value

  return <div className={`${styles.contract}`}>
    <div className={`${styles.innerContract} contract-centered`}>
      <Clause title={i18n.t('contract.general.tenant_contact')}>
        <SubClause>
          <div className={`${styles.row}`}>
            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.FIRST_NAME}`)}
              value={firstName}
            />

            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.LAST_NAME}`)}
              value={lastName}
            />
          </div>

          <div className={`${styles.row}`}>
            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.PHONE}`)}
              value={phone}
            />

            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.EMAIL}`)}
              required={true}
              value={email}
            />
          </div>
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.title')}>
        <SubClause>
          <TextFieldReadOnly
            value={contractTitle}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.between')}>
        <SubClause title={i18n.t('contract.general.landlord')}>
          <div>{landlordText}</div>
        </SubClause>
        <SubClause title={i18n.t('contract.general.tenant')}>
          <div>{tenantText}</div>
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.background_and_reason')} index="1.">
        <SubClause>
          <TextEditorReadOnly
            value={backgroundAndReason}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.general.rental_property')} index="2.">
        <SubClause title={i18n.t('contract.general.property')} index="2.1">
          <div className={`${styles.row}`}>
            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.PROPERTY_SHOPPING_MALL}`)}
              value={shoppingMall}
              // value={(fields[RentalPropertyInputFields.SHOPPING_MALL]?.value || null) as string}
              // placeholder={i18n.t(`contract.placeholders.${AddendumRentalAgreementStorageUnitInputFields.SHOPPING_MALL}`)}
            />

            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.PROPERTY_ADDRESS}`)}
              value={propertyAddress}
              // value={(fields[StorageUnitInputFields.ADDRESS]?.value || null) as string}
              // placeholder={i18n.t(`contract.placeholders.${StorageUnitInputFields.ADDRESS}`)}
            />
          </div>

          <div className={`${styles.row}`}>
            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.PROPERTY_MUNICIPALITY}`)}
              value={propertyMunicipality}
              // value={(fields[StorageUnitInputFields.MUNICIPALITY]?.value || null) as string}
              // placeholder={i18n.t(`contract.placeholders.${StorageUnitInputFields.MUNICIPALITY}`)}
            />

            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.PROPERTY_PARCEL_NUMBER}`)}
              value={propertyParcelNumber}
              // value={(fields[AddendumRentalAgreementStorageUnitInputFields.PARCEL_NUMBER]?.value || null) as string}
              // placeholder={i18n.t(`contract.placeholders.${AddendumRentalAgreementStorageUnitInputFields.PARCEL_NUMBER}`)}
            />
          </div>

          <div className={`${styles.row}`}>
            <NumberFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.PROPERTY_LOCAL_NO}`)}
              value={propertyLocalNumber}
              // value={(fields[RentalPropertyInputFields.LOCAL_NUMBER]?.value || null) as number}
              // placeholder={i18n.t(`contract.placeholders.${RentalPropertyInputFields.LOCAL_NUMBER}`)}
            />

            <NumberFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.PROPERTY_LEVEL}`)}
              value={propertyLevel}
              // value={(fields[RentalPropertyInputFields.LEVEL]?.value || null) as number}
              // placeholder={i18n.t(`contract.placeholders.${RentalPropertyInputFields.LEVEL}`)}
            />

            <NumberFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.PROPERTY_APPROX_EXCL_AREA}`)}
              value={propertyApproxExclusiveArea}
              // value={(fields[RentalPropertyInputFields.APPROX_EXCLUSIVE_AREA]?.value || null) as number}
              // placeholder={i18n.t(`contract.placeholders.${RentalPropertyInputFields.APPROX_EXCLUSIVE_AREA}`)}
            />

            <NumberFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.PROPERTY_COMMON_AREA}`)}
              value={propertyCommonArea}
              // value={(fields[RentalPropertyInputFields.COMMON_AREA]?.value || null) as number}
            />
          </div>

          <VisibleIf predicate={!propertyTotalArea?.value}>
            <div className="mt-3 font-bold text-right">
              {i18n.t('contract.general.total_area_fill_fields')}
            </div>
          </VisibleIf>

          <VisibleIf predicate={!!propertyTotalArea?.value}>
            <div className="flex flex-row flex-grow items-center justify-end mt-6">
              <div>
                <div className="text-right mr-6 mt-1">{i18n.t(`contract.labels.${AddendumFields.PROPERTY_TOTAL_AREA}`)}</div>
              </div>
              <TextFieldReadOnly
                extraClassNames={`${styles.totalAreaField}`}
                // value={AreaFormatter(fields.propertyTotalArea?.value)}
                value={AreaFormatter(propertyTotalArea?.value)}
              />
            </div>
          </VisibleIf>
        </SubClause>
        <SubClause title={i18n.t('contract.addendums.template.storage.propertyConditionAndUse')} index="2.2">
          <TextEditorReadOnly
            value={propertyConditionAndUse}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.general.rental_period')} index="3.">
        <SubClause>
          <div className={`${styles.row}`}>
            <DateFieldReadOnly
              extraClassNames="flex-grow"
              label={i18n.t(`contract.labels.${AddendumFields.RENTAL_START}`)}
              value={rentalStart}
            />
            <DateFieldReadOnly
              extraClassNames="flex-grow"
              label={i18n.t(`contract.labels.${AddendumFields.RENTAL_END}`)}
              value={rentalEnd}
            />
            <DateFieldReadOnly
              extraClassNames="flex-grow"
              label={i18n.t(`contract.labels.${AddendumFields.RENTAL_PAYMENT_START}`)}
              value={rentalPaymentStart}
            />
          </div>
          <div className={`${styles.toggleHeader}`}>
            <div className="sub-clause-header">{i18n.t('contract.general.rental_period_potential_options')}</div>
            <Switch
              // key={AddendumFields.RENTAL_HAS_EXTENSION_OPTIONS}
              className={`secondary-switch-${!!hasExtensionOptions ? 'checked' : 'unchecked'}`}
              isChecked={!!hasExtensionOptions}
              isDisabled={true}
            />
          </div>

          <VisibleIf predicate={!!hasExtensionOptions}>
            {/* <div className="flex flex-row items-center">
          <div>{i18n.t(`contract.labels.${AddendumFields.RENTAL_HAS_EXTENSION_OPTIONS}`)}</div>
        </div> */}

            <div className="flex flex-col gap-4">
              <RadioButtonGroup selectedValue={extensionType} className="flex w-full gap-8">
                <RadioButton
                  key={v4()}
                  value={RentalPeriodExtensionType.EXTENSION}
                  isDisabled={true}
                >
                  {i18n.t(`contract.fields.${AddendumFields.RENTAL_EXTENSION_TYPE}.${RentalPeriodExtensionType.EXTENSION}`)}
                </RadioButton>
                <RadioButton
                  key={v4()}
                  value={RentalPeriodExtensionType.RENEWAL}
                  isDisabled={true}
                >
                  {i18n.t(`contract.fields.${AddendumFields.RENTAL_EXTENSION_TYPE}.${RentalPeriodExtensionType.RENEWAL}`)}
                </RadioButton>
              </RadioButtonGroup>

              <div className="w-1/2">
                <TextFieldReadOnly
                  label={i18n.t(`contract.labels.${AddendumFields.RENTAL_EXTENSION_NUMBER_OF_YEARS}`)}
                  value={extensionYears}
                />
              </div>
            </div>
            <VisibleIf predicate={extensionType === RentalPeriodExtensionType.EXTENSION}>
              <p>
                {
                  i18n.t('contract.general.rental_period_extension_description', {
                    years: extensionYears
                  })
                }
              </p>
            </VisibleIf>
            <VisibleIf predicate={extensionType === RentalPeriodExtensionType.RENEWAL}>
              <p>
                {
                  i18n.t('contract.general.rental_period_renewal_description', {
                    years: extensionYears
                  })
                }
              </p>
            </VisibleIf>
          </VisibleIf>
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.template.storage.rental_costs')} index="4.">
        <SubClause title={i18n.t('contract.general.storage_rent')} index="4.1">
          <div className="flex-grow w-1/2">
            <div className="w-full mt-3">{i18n.t(`contract.general.storage_rent_description`)}</div>
          </div>

          <NumberFieldReadOnly
            label={i18n.t(`contract.labels.${AddendumFields.STORAGE_RENT}`)}
            extraClassNames="w-1/2"
            value={parseFloat(storageRent)}
          />
        </SubClause>

        <SubClause title={i18n.t('contract.general.tenant_contact')} index="4.2">
          <div>
            {i18n.t(`contract.general.shared_costs_description`)}
          </div>

          <div className={`${styles.row}`}>
            <NumberFieldReadOnly
              label={i18n.t(`contract.labels.${AddendumFields.SHARED_COSTS}`)}
              value={sharedCosts}
            />
          </div>
        </SubClause>

        <SubClause title={i18n.t('contract.general.index_adjustment')} index="4.3">
          <div className={`${styles.row}`}>
            <DateFieldReadOnly
              extraClassNames="flex-grow w-1/2"
              label={i18n.t(`contract.labels.${AddendumFields.FIRST_TIME_REGULATION}`)}
              value={firstTimeRegulation}
            />
            <DateFieldReadOnly
              extraClassNames="flex-grow w-1/2"
              label={i18n.t(`contract.labels.${AddendumFields.BASIC_INDEX}`)}
              value={basicIndex}
            />
          </div>
        </SubClause>

        <SubClause title={i18n.t('contract.general.payment_provision')} index="4.4">
          <RadioButtonGroup selectedValue={provisionType} className="flex flex-row gap-8">
            <RadioButton
              key={v4()}
              value={PaymentProvisionType.QUARTERLY_IN_ADVANCE}
              isDisabled={true}
            >
              {i18n.t(`contract.fields.${AddendumFields.PROVISION_TYPE}.${PaymentProvisionType.QUARTERLY_IN_ADVANCE}`)}
            </RadioButton>
            <RadioButton
              key={v4()}
              value={PaymentProvisionType.MONTHLY_IN_ADVANCE}
              isDisabled={true}
            >
              {i18n.t(`contract.fields.${AddendumFields.PROVISION_TYPE}.${PaymentProvisionType.MONTHLY_IN_ADVANCE}`)}
            </RadioButton>
          </RadioButtonGroup>
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.general.vat')} index="5.">
        <div className="flex flex-row items-center">
          <RadioButtonGroup selectedValue={vatStatus} className="flex flex-col gap-4">
            <RadioButton
              key={v4()}
              value={VATType.EXCLUSIVELY_WITHIN_VAT}
              isDisabled={true}
            >
              {i18n.t(`contract.fields.${AddendumFields.BUSINESS_VAT_STATUS}.exclusively_within_vat`)}

            </RadioButton>
            <RadioButton
              key={v4()}
              value={VATType.EXCLUSIVELY_OUTSIDE_VAT}
              isDisabled={true}
            >
              {i18n.t(`contract.fields.${AddendumFields.BUSINESS_VAT_STATUS}.exclusively_outside_vat`)}
            </RadioButton>
            <RadioButton
              key={v4()}
              value={VATType.PARTIALLY_WITHIN_VAT}
              isDisabled={true}
            >
              <div className="flex items-center gap-6">
                <VisibleIf predicate={vatStatus === VATType.PARTIALLY_WITHIN_VAT}>
                  {/*<div className="w-1/2">*/}
                  {/*  <NumberFieldReadOnly*/}
                  {/*    // label={i18n.t(`contract.labels.${AddendumFields.BUSINESS_VAT_PERCENTAGE}`)}*/}
                  {/*    value={vatPercentage}*/}
                  {/*  />*/}
                  {/*</div>*/}
                  <strong>{vatPercentage}</strong>
                </VisibleIf>
                {i18n.t(`contract.fields.${AddendumFields.BUSINESS_VAT_STATUS}.partially_within_vat`)}
              </div>
            </RadioButton>
          </RadioButtonGroup>
        </div>
      </Clause>

      <Clause title={i18n.t('contract.general.derogation')} index="6.">
        <SubClause>
          <TextEditorReadOnly
            value={derogation}
          />
        </SubClause>
      </Clause>

      <Clause title={i18n.t('contract.addendums.other_provisions')} index="7.">
        <SubClause>
          <TextEditorReadOnly
            value={otherProvisions}
          />
        </SubClause>
      </Clause>
    </div>
  </div>
}
