import React from 'react'

// import { Contract, ContractPageSubview, ContractType, ContractViewModel, RouteName } from "src/types"

import * as producers from './producers'
import { ContractPageSubview, RouteName } from 'src/types'

import { LoadingOverlay } from 'src/components/LoadingOverlay'
import { NewAddendumModal } from '../ContractV2/DigitalContract/AddendumsTab/NewAddendumModal'
import { Navbar } from './components/Navbar/Navbar'

import { Attachments } from './DigitalContract/AttachmentsTab/Attachments'
import { Summary } from './DigitalContract/SummaryTab/Summary'
import { MoveToSigningModal } from './modals/MoveToSigningModal/MoveToSigningModal'
import { manageLoadingState } from './Contract.manageLoadingState'
import { Addendums } from './DigitalContract/AddendumsTab'

import { ContractFlow, ContractV2 as ContractV2Model } from './contract.types'
// import { DigitalContract } from './DigitalContract'
import { Parties } from './DigitalContract/PartiesTab/PartiesTab'
import styles from './styles.module.css'
import { StopSigningModal } from './modals/StopSigningModal/StopSigningModal'
import { PaperContract } from './PaperContract/PaperContract'
import { DigitalContract } from './DigitalContract/ContractTab/DigitalContract'
import { MigrationBanner } from 'src/components_generic/MigrateBanner'
import contractsMapping from '../../../../functions/contractIdsMapping.json'

const ContractV2: view = ({
  originalContract = observe.contract.originalv2,
  subview = observe.routes.list[RouteName.CONTRACT_V2].data.subview,
  stateTransition = observe.contract.loading.stateTransition,
}) => {
  if (!subview) return null
  if (!originalContract) return null

  const contract = originalContract as ContractV2Model
  let selectedView = null
  switch (subview) {
    case ContractPageSubview.SUMMARY:
      selectedView = <Summary />
      break
    case ContractPageSubview.CONTRACT:
      // if (contract.flow === ContractFlow.PAPER) {
      //   selectedView = <PaperContract />
      // }
      if (contract.flow === ContractFlow.DIGITAL) {
        selectedView = <DigitalContract />
      } else if (contract.flow === ContractFlow.PAPER) {
        selectedView = <PaperContract />
      }
      break
    case ContractPageSubview.ATTACHMENTS:
      selectedView = <Attachments />
      break
    case ContractPageSubview.ADDENDUMS:
      selectedView = <Addendums />
      break
    case ContractPageSubview.PARTIES:
      selectedView = <Parties />
      break
    default:
      break
  }

  const foundContractId = contractsMapping.find((c: any) => c.legacyId === originalContract.id)?.id

  const url = foundContractId ? `https://leko.mittleieforhold.no/contract/${foundContractId}` : 'https://leko.mittleieforhold.no'

  return (
    <>
      {stateTransition && <LoadingOverlay />}
      <Navbar key="navbar" />
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <MigrationBanner url={url} />
      </div>
      <div key="page" className={`${styles.contractLayout}`}>
        {selectedView}
        {/* <main className={`flex-grow`}>{selectedView}</main> */}
        {/*<Sidebar />*/}
      </div>
      <NewAddendumModal />
      <MoveToSigningModal />
      <StopSigningModal />
    </>
  )
}

ContractV2.producers([...Object.values(producers), manageLoadingState])
export { ContractV2 }
