import i18n from 'src/producers/languages/i18n'
import { PersonSilhouetteIcon, RightArrowCircleIcon } from 'src/assets/svg'
import { Modal } from 'src/components'
import { TextField } from 'src/components_generic/TextField'
import { RadioButton, RadioButtonGroup } from '@c11/components.radio-button'
import { v4 } from 'uuid'
import { PartyType } from 'src/types'
import { VisibleIf } from 'src/components_generic/visible-if'
import { Dropdown, MenuProps } from 'antd'
import { SignerDropdownMenu } from './SignerDropdownMenu'
import { DropdownField } from 'src/components_generic/DropdownField'
import styles from '../../styles.module.css'
import { PhoneField } from '../../../../components_generic/PhoneField'

enum AddPartyModalInputFields {
  FIRST_NAME = 'first-name',
  LAST_NAME = 'last-name',
  POSITION = 'position',
  EMAIL = 'email',
  PHONE = 'phone-number',
}

const INPUT_FIELDS_PROP_MAPPINGS = {
  [AddPartyModalInputFields.FIRST_NAME]: 'firstName',
  [AddPartyModalInputFields.LAST_NAME]: 'lastName',
  [AddPartyModalInputFields.POSITION]: 'position',
  [AddPartyModalInputFields.EMAIL]: 'email',
  [AddPartyModalInputFields.PHONE]: 'phone',
}

enum SigningType {
  NORWEGIAN = 'bankid-no',
  SWEDISH = 'bankid-se',
  DANISH = 'nets-eident-dk-mitid',
}

export const PartyModalWindow: view = ({
  partyModal = observe.modal.partyModal,

  tenantContacts = get.contract.originalv2.tenant.mainContacts,
  signers = get.contract.originalv2.signers,
  landlords = get.landlords,

  updatePartyModal = update.modal.partyModal,
  updateParty = update.contract.changes.parties.update,
}) => {
  const {
    isOpen,
    email,
    firstName,
    lastName,
    partyType,
    recipientAction,
    phone,
    position,
    selectedTenant,
    selectedLandlord,
    signingMethod,
    modalType,
    order,
    partyId,
  } = partyModal || {}

  if (!isOpen) return null

  const modalText =
    modalType === 'add' ? i18n.t('parties_page.modal_add_participant') : i18n.t('parties_page.modal_edit_participant')

  const signingMethodIsValid = recipientAction === 'sign' ? !!signingMethod : !!recipientAction
  const isValid = !!email && !!firstName && !!lastName && !!partyType && !!recipientAction && signingMethodIsValid

  landlords = landlords?.value()?.data || []
  tenantContacts = tenantContacts?.value() || []
  signers = signers?.value() || []

  const hasLandlords = landlords.length > 0
  const hasTenantContacts = tenantContacts.length > 0

  return (
    <Modal
      title={modalText}
      okButtonText={modalText}
      okButtonDisabled={!isValid}
      onOkClick={() => {
        updateParty.set({
          email,
          firstName,
          lastName,
          phone,
          partyType,
          recipientAction,
          position,
          signingMethod,
          order,
          partyId,
        })

        updatePartyModal.remove()
      }}
      onCancelClick={() => {
        updateParty.remove()
        updatePartyModal.remove()
      }}
      width={600}>
      <div id="add-party-modal-window" className={styles.partyModalCard}>
        <div className="flex-grow flex flex-row justify-between items-center mb-4">
          <div className="flex flex-row ml-1">
            <PersonSilhouetteIcon className="parties-tab-modal-person-icon" />
            <h4 className="text-black fw-700">
              {i18n.t('parties_page.modal_participant_role')}
              <span className="text-pink-600 pl-1">*</span>
            </h4>
          </div>
          <div className="flex flex-row mr-1">
            <RadioButtonGroup selectedValue={partyType} className="flex flex-row gap-12">
              <RadioButton
                key={v4()}
                value={PartyType.TENANT}
                isDisabled={false || true}
                onClick={() => {
                  updatePartyModal.merge({
                    partyType: PartyType.TENANT,
                  })
                }}>
                <div className="font-bold" style={{ marginTop: '-2px' }}>
                  {i18n.t('general.tenant')}
                </div>
              </RadioButton>
              <RadioButton
                key={v4()}
                value={PartyType.LANDLORD}
                isDisabled={false || true}
                onClick={() => {
                  updatePartyModal.merge({
                    partyType: PartyType.LANDLORD,
                  })
                }}>
                <div className="font-bold" style={{ marginTop: '-2px' }}>
                  {i18n.t('general.landlord')}
                </div>
              </RadioButton>
            </RadioButtonGroup>
          </div>
        </div>

        <VisibleIf predicate={partyType === PartyType.TENANT && hasTenantContacts}>
          <div id="add-party-dropdown-container" className={styles.partyModalInnerCard}>
            <Dropdown
              getPopupContainer={(trigger) => {
                return document.getElementById('add-party-dropdown-container') as HTMLElement
              }}
              menu={{
                items: SignerDropdownMenu({
                  items: tenantContacts,
                  onClick: (tenant) => {
                    updatePartyModal.merge({
                      selectedTenant: tenant,
                      firstName: tenant.firstName,
                      lastName: tenant.lastName,
                      email: tenant.email,
                      phone: tenant.phone,
                    })

                    Object.values(AddPartyModalInputFields).forEach((id) => {
                      const value = tenant[INPUT_FIELDS_PROP_MAPPINGS[id]] || ''
                      UpdateUIInputField(id, value)
                    })
                  },
                }),
              }}
              overlayStyle={{ borderRadius: 0 }}
              placement="bottom"
              trigger={['click']}>
              <div className="w-full">
                <DropdownField label={i18n.t('parties_page.modal_choose_landlord_text')} value={selectedTenant?.firstName} />
              </div>
            </Dropdown>
          </div>
        </VisibleIf>

        <VisibleIf predicate={partyType === PartyType.LANDLORD && hasLandlords}>
          <div id="add-party-dropdown-container" className={styles.partyModalInnerCard}>
            <Dropdown
              getPopupContainer={(trigger) => {
                return document.getElementById('add-party-dropdown-container') as HTMLElement
              }}
              menu={{
                items: SignerDropdownMenu({
                  items: landlords,
                  onClick: (landlord) => {
                    updatePartyModal.merge({
                      selectedLandlord: landlord,
                      firstName: landlord.firstName,
                      lastName: landlord.lastName,
                      email: landlord.email,
                      position: landlord.position,
                      phone: landlord.phone,
                    })
                    Object.values(AddPartyModalInputFields).forEach((id) => {
                      const value = landlord[INPUT_FIELDS_PROP_MAPPINGS[id]]
                      UpdateUIInputField(id, value)
                    })
                  },
                }),
              }}
              overlayStyle={{ borderRadius: 0 }}
              placement="bottom"
              trigger={['click']}>
              <div className="w-full">
                <DropdownField label={i18n.t('parties_page.modal_choose_landlord_text')} value={selectedLandlord?.firstName} />
              </div>
            </Dropdown>
          </div>
        </VisibleIf>

        <div className={styles.partyModalInnerCard}>
          <div className="flex-grow flex flex-row gap-8">
            <div className="flex-grow w-1/2">
              <TextField
                name={AddPartyModalInputFields.FIRST_NAME}
                label={i18n.t('parties_page.modal_first_name')}
                required
                value={firstName}
                onChange={(text) => {
                  updatePartyModal.merge({
                    firstName: text,
                  })
                }}
              />
            </div>
            <div className="flex-grow w-1/2">
              <TextField
                name={AddPartyModalInputFields.LAST_NAME}
                label={i18n.t('parties_page.modal_last_name')}
                required
                value={lastName}
                onChange={(text) => {
                  updatePartyModal.merge({
                    lastName: text,
                  })
                }}
              />
            </div>
          </div>

          <div className="flex-grow">
            <TextField
              name={AddPartyModalInputFields.POSITION}
              label={i18n.t('parties_page.modal_position')}
              // required
              value={position}
              onChange={(text) => {
                updatePartyModal.merge({
                  position: text,
                })
              }}
            />
          </div>

          <div className="flex-grow">
            <TextField
              name={AddPartyModalInputFields.EMAIL}
              label={i18n.t('parties_page.modal_email')}
              required
              value={email}
              onChange={(text) => {
                updatePartyModal.merge({
                  email: text,
                })
              }}
            />
          </div>

          <div className="flex-grow">
            <div className="w-full">
              <PhoneField
                placeholder={i18n.t('parties_page.modal_phone')}
                value={phone || undefined}
                defaultCountry={'NO'}
                label={i18n.t('parties_page.modal_phone')}
                onChange={(value: any) => {
                  updatePartyModal.merge({
                    phone: value,
                  })
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.partyModalCard}>
        <div className="flex flex-row">
          <RightArrowCircleIcon className="text-pink-600" />
          <div className="ml-3 mr-8">
            <span className="text-black">{i18n.t('parties_page.modal_required_action')}</span> *
          </div>
          <RadioButtonGroup selectedValue={recipientAction} className="flex flex-row gap-8">
            <RadioButton
              key={v4()}
              value={'sign'}
              isDisabled={false || true}
              onClick={() => {
                updatePartyModal.merge({
                  recipientAction: 'sign',
                })
              }}>
              <div className="font-bold" style={{ marginTop: '-2px' }}>
                {i18n.t('parties_page.action_sign')}
              </div>
            </RadioButton>
            <RadioButton
              key={v4()}
              value={'review'}
              isDisabled={false || true}
              onClick={() => {
                updatePartyModal.merge({
                  recipientAction: 'review',
                })
              }}>
              <div className="font-bold" style={{ marginTop: '-2px' }}>
                {i18n.t('parties_page.action_approve')}
              </div>
            </RadioButton>
          </RadioButtonGroup>
        </div>

        <div>
          <VisibleIf predicate={recipientAction === 'sign'}>
            <Dropdown
              menu={{
                items: SigningDropdownItemsFactory({
                  onClick: (key: any) => {
                    updatePartyModal.merge({ signingMethod: key })
                  },
                }),
              }}
              trigger={['click']}
              placement="bottom">
              <div className="w-full">
                <DropdownField value={i18n.t(`contract.signingType.${signingMethod}`)} />
              </div>
            </Dropdown>
          </VisibleIf>
        </div>
      </div>
    </Modal>
  )
}

const UpdateUIInputField = (id: string, value?: string) => {
  if (!value) return

  const input = document.getElementById(id) as HTMLInputElement
  if (!input) return

  input.value = value
}

function SigningDropdownItemsFactory(params: any): MenuProps['items'] {
  return [
    {
      key: SigningType.NORWEGIAN,
      label: (
        <div className="flex-grow" onClick={(_) => params.onClick(SigningType.NORWEGIAN)}>
          {i18n.t(`contract.signingType.${SigningType.NORWEGIAN}`)}
        </div>
      ),
    },
    {
      key: SigningType.SWEDISH,
      label: (
        <div className="flex-grow" onClick={(_) => params.onClick(SigningType.SWEDISH)}>
          {i18n.t(`contract.signingType.${SigningType.SWEDISH}`)}
        </div>
      ),
    },
    {
      key: SigningType.DANISH,
      label: (
        <div className="flex-grow" onClick={(_) => params.onClick(SigningType.DANISH)}>
          {i18n.t(`contract.signingType.${SigningType.DANISH}`)}
        </div>
      ),
    },
  ]
}
