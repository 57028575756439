import { TextEditor } from 'src/components'
import { VisibleIf } from 'src/components_generic/visible-if'
import i18n from 'src/producers/languages/i18n'
import React from 'react'

enum OtherAddendumsFields {
  DESCRIPTION = 'otherAddendums',
}

export const OtherAddendums: view = ({
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status,
}) => {
  const isActiveContract = contractStatus === 'active'
  const displayReadOnlyView = isActiveContract || isLocked || true

  return (
    <>
      <VisibleIf predicate={!displayReadOnlyView}>
        <OtherAddendumsEdit />
      </VisibleIf>
      <VisibleIf predicate={displayReadOnlyView}>
        <OtherAddendumsReadOnly />
      </VisibleIf>
    </>
  )
}

const OtherAddendumsEdit: view = ({
  fields = observe.contract.originalv2.fields,
  setChanges = update.contract.changes.fields[param.name],
  resetToDefault = update.contract.changes.resetToDefault[param.name],
}) => {
  const GetDescriptionText = () => fields?.[OtherAddendumsFields.DESCRIPTION]?.value

  return (
    <div>
      <h2>{i18n.t('contract_page.other_addendums_clause_h')}</h2>
      <div className="flex flex-col gap-4 sub-clause-card pb-10">
        <div className="flex flex-row items-center">
          <div className="w-full">
            <TextEditor
              name={OtherAddendumsFields.DESCRIPTION}
              value={GetDescriptionText()}
              onChange={(e: any) => {
                if (!e) return
                setChanges.set({ value: e }, { name: OtherAddendumsFields.DESCRIPTION })
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const OtherAddendumsReadOnly: view = ({ fields = observe.contract.originalv2.fields }) => {
  return (
    <div>
      <h2>{i18n.t('contract_page.other_addendums_clause_h')}</h2>

      <div className="flex flex-col gap-4 sub-clause-card pb-10">
        <div className="flex flex-row items-center">
          <div className="w-full">
            <TextEditor value={fields[OtherAddendumsFields.DESCRIPTION]?.value} readOnly={true} />
          </div>
        </div>
      </div>
    </div>
  )
}
