import { Spinner } from "@c11/components";
import { NumberField } from "src/components_generic/NumberField";
import { TextField, TextFieldReadOnly } from "src/components_generic/TextField";
import { VisibleIf } from "src/components_generic/visible-if";
import i18n from "src/producers/languages/i18n";

export const ContractName: view = ({
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status
}) => {
  const isActiveContract = contractStatus === "active";
  const displayReadOnlyView = isActiveContract || isLocked || true;

  return <>
    <VisibleIf predicate={!displayReadOnlyView}>
      <ContractNameComponent />
    </VisibleIf>
    <VisibleIf predicate={displayReadOnlyView}>
      <ContractNameComponentReadOnly />
    </VisibleIf>
  </>
};

enum ContractNameInputFields {
  CONTRACT_TITLE = "title",
};

const ContractNameComponent: view = ({
  fields = observe.contract.originalv2.fields,
  setChanges = update.contract.changes.fields[param.name]
}) => {
  // if (!contract?.metadata?.readOnly) return null

  return <div>
    <div className="flex flex-col gap-4 sub-clause-card">
      <div className="flex flex-row items-center">
        <TextField label={i18n.t("contract.labels.contractName")}
            name={ContractNameInputFields.CONTRACT_TITLE}
            required={true}
            value={fields[ContractNameInputFields.CONTRACT_TITLE]?.value}
            onChange={value => setChanges.set({ value }, {
              name: ContractNameInputFields.CONTRACT_TITLE
            })}
          />
      </div>
    </div>
  </div>
}

const ContractNameComponentReadOnly: view = ({
  contract = observe.contract.originalv2,
  fields = observe.contract.originalv2.fields,
}) => {

  return <div>
  <div className="flex flex-col gap-4 sub-clause-card">
    <div className="flex flex-row items-center">
      <TextFieldReadOnly label={i18n.t("contract.labels.contractName")}
          // name={ContractNameInputFields.CONTRACT_NAME}
          required={true}
          value={fields[ContractNameInputFields.CONTRACT_TITLE]?.value}
        />
    </div>
  </div>
</div>
}
