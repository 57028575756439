import { Dropdown, Menu, notification } from 'antd'
import { TextField } from 'src/components_generic/TextField'
import { VisibleIf } from 'src/components_generic/visible-if'

import './RentalPremise.css'
import i18n from 'src/producers/languages/i18n'
import { BuildingDropdownMenuFactory } from '../templates/shoppingMall/dropdowns/BuildingDropdown'

let timer: any = null

export const RentalPremise: view = ({
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status
}) => {
  const isActiveContract = contractStatus === 'active'
  const displayReadOnlyView = isActiveContract || isLocked || true

  return <>
    <VisibleIf predicate={!displayReadOnlyView}>
      <RentalPremiseComponent/>
    </VisibleIf>
    <VisibleIf predicate={displayReadOnlyView}>
      <RentalPremiseComponentReadOnly/>
    </VisibleIf>
  </>
}

const RentalPremiseComponent: view = ({
  shoppingMalls = get.domain.buildings,
  contract = observe.contract.originalv2,
  buildingsSearchQuery = observe.contract.originalv2View.buildingsSearchQuery,
  updateBuildingsSearchQuery = update.contract.originalv2View.buildingsSearchQuery,
  updateBuilding = update.contract.changes.building
}) => {
  // if (!contract?.metadata?.readOnly) return null
  const ShouldDisplayBuildingsDropdownItems = () => {
    if (!buildingsSearchQuery) return false
    if (buildingsSearchQuery.length < 2) return false

    return true
  }
  const BuildingsDropdownItems = () => {
    if (!buildingsSearchQuery) return []
    const malls = Object.keys(shoppingMalls.value()).map(key => ({
      key,
      ...shoppingMalls.value()[key]
    }))
    const nameFilter = (mall: typeof malls[number]) => {
      const query = buildingsSearchQuery.toLowerCase()
      const regularMatchTest = mall.name?.toLowerCase().indexOf(query) > -1
      const specialCharacterSearch = mall.searchName?.toLowerCase().indexOf(query) > -1
      // const knownCharactersMatchTest = KnownSpecialCharactersKeys.some((key) => {
      //   return KnownSpecialCharacters[key].some(character => {
      //     const replaced = buildingsSearchQuery.toLowerCase().replaceAll(key, character);
      //     return mall.name.toLowerCase().indexOf(replaced) > -1;
      //   });
      // });
      return regularMatchTest || specialCharacterSearch
    }

    return malls.filter(nameFilter)
  }

  const building = contract.building

  return <div>
    <h2>{i18n.t('contract.general.rental_premise')}</h2>

    <div className="flex flex-col gap-4 sub-clause-card">
      <div className="flex flex-row items-center">
        <div className="flex flex-col justify-center flex-grow w-1/2 gap-2">
          <div className="w-full flex-grow">{i18n.t(`contract.general.premise_of`)}</div>
          <VisibleIf predicate={!!(building)}>
            <div className="flex flex-row flex-grow w-full">
              <div className="mr-4">
                <img src={building?.buildingImageUrl} alt="shopping mall image" className="rental-premise-image"/>
              </div>
              <div className="flex-grow flex flex-col">
                <div>{building?.name} - {building?.departmentname}</div>
                <div>{building?.address}</div>
              </div>
            </div>
          </VisibleIf>
        </div>
        <div className="flex-grow w-1/2 ml-8">
          <Dropdown
            // overlay={BuildingDropdownMenu({
            //   items: BuildingsDropdownItems(),
            //   onClick: (key) => {
            //     updateBuildingsSearchQuery.set(null);
            //     updateBuilding.set(shoppingMalls.value()[key]);
            //   }
            // })}
            menu={{
              items: BuildingDropdownMenuFactory({
                items: BuildingsDropdownItems(),
                onClick: (key) => {
                  updateBuildingsSearchQuery.set(null)
                  const building = shoppingMalls.value()[key]
                  updateBuilding.set(shoppingMalls.value()[key])

                  const element = document.getElementById('buildingSearch') as HTMLInputElement
                  if (element) {
                    element.value = ''
                  }
                }
              })
            }}
            open={ShouldDisplayBuildingsDropdownItems()}
            overlayStyle={{ borderRadius: 0 }}
            trigger={['click']}
          >
            <TextField
              name={'buildingSearch'}
              placeholder={i18n.t(`contract.placeholders.rentalPremise`)}
              onChange={(e) => {
                console.log(e)
                if (timer) clearTimeout(timer)
                timer = setTimeout(() => updateBuildingsSearchQuery.set(e), 500)
              }}
            />
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
}

const RentalPremiseComponentReadOnly: view = ({
  contract = observe.contract.originalv2
}) => {
  // if (contract?.metadata?.readOnly) return null;
  const building = contract.building

  return <div>
    <h2>{i18n.t('contract.general.rental_premise')}</h2>

    <div className="flex flex-col justify-center flex-grow w-full gap-2">
      <VisibleIf predicate={!!(building)}>
        <div className="w-full flex-grow">{i18n.t(`contract.general.premise_of`)}</div>
        <div className="flex flex-row flex-grow w-full">
          <div className="mr-4">
            <img src={building?.buildingImageUrl} alt="shopping mall image" className="rental-premise-image"/>
          </div>
          <div className="flex-grow flex flex-col">
            <div>{building?.name} - {building?.departmentname}</div>
            <div>{building?.address}</div>
          </div>
        </div>
      </VisibleIf>
      <VisibleIf predicate={!(building)}>
        <div>No building currently selected</div>
      </VisibleIf>
    </div>
  </div>
}

// type BuildingDropdownMenuProps = {
//   onClick: (key: string) => void;
//   items: Array<{
//     key: string;
//     name: string;
//     address: string;
//     buildingImageUrl: string;
//   }>;
// };

// const BuildingDropdownMenu = (props: BuildingDropdownMenuProps) => {
//   // if (props.items.length === 0) return <Menu style={{ borderRadius: 0 }}>
//   //   <Menu.Item>{i18n.t(`general.no_results_found`)}</Menu.Item>
//   // </Menu>


//   return <Menu style={{ borderRadius: 0 }}>
//     {
//       props.items.map(item => <Menu.Item key={item.key} className="flex flex-row" onClick={() => props.onClick(item.key)}>
//         <div className="mr-4">
//           <img src={item.buildingImageUrl} alt="" className="rental-premise-image" />
//         </div>
//         <div className="flex flex-col gap-1 flex-grow">
//           <div>{item.name}</div>
//           <div>{item.address}</div>
//         </div>
//       </Menu.Item>)
//     }
//   </Menu>
// }
