import { RadioButton, RadioButtonGroup, Spinner, Switch } from '@c11/components'
import { DatePicker, Dropdown, Menu, MenuProps } from 'antd'
import moment from 'moment'
import { DateField, DateFieldReadOnly } from 'src/components_generic/DateField'
import { DropdownField } from 'src/components_generic/DropdownField'
import { TextFieldReadOnly } from 'src/components_generic/TextField'
import { VisibleIf } from 'src/components_generic/visible-if'
import i18n from 'src/producers/languages/i18n'
import { v4 } from 'uuid'
import styles from '../../../../../styles.module.css'
import { RenewalPeriodDropdownItemsFactory } from '../dropdowns/RenewalPeriodDropdown'
import { VisibleIfInvalidField } from 'src/pages/ContractV2/components/VisibleIfInvalidField'

const GetMomentDate = (value?: string) => {
  if (!value) return null
  return moment(value).utc()
}

export const RentalPeriod: view = ({
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status
}) => {
  const isActiveContract = contractStatus === 'active'
  const displayReadOnlyView = isActiveContract || isLocked || true

  return <>
    <VisibleIf predicate={!displayReadOnlyView}>
      <RentalPeriodShoppingMall/>
    </VisibleIf>
    <VisibleIf predicate={displayReadOnlyView}>
      <RentalPeriodShoppingMallReadOnly/>
    </VisibleIf>
  </>
}

export enum RentalPeriodShoppingMallInputFields {
  RENTAL_START = 'rentalStart',
  RENTAL_END = 'rentalEnd',
  RENTAL_PAYMENT_START = 'rentalPaymentStart',
  OPENING_DATE = 'storeOpening',
  HAS_EXTENSION_OPTIONS = 'rentalPeriodHasOptions',
  EXTENSION_TYPE = 'rentalPeriodExtensionType',
  NUMBER_OF_YEARS = 'rentalPeriodExtensionYears'
}

export enum RentalPeriodExtensionType {
  EXTENSION = 'extension',
  RENEWAL = 'renewal'
}

const RentalPeriodShoppingMall: view = ({
  fields = observe.contract.originalv2.fields,
  setChanges = update.contract.changes.fields[param.name],
  setMiscChanges = update.contract.changes.miscellaneous[param.name]
  // hasExtensionOptions = observe.contract.originalv2View.hasExtensionOptions,
  // updateHasExtensionOptions = update.contract.originalv2View.hasExtensionOptions
}) => {
  // if (!contract?.metadata?.readOnly) return null
  const GetRentalStartDate = () => fields?.[RentalPeriodShoppingMallInputFields.RENTAL_START]?.value
  const GetRentalEndDate = () => fields?.[RentalPeriodShoppingMallInputFields.RENTAL_END]?.value
  const GetOpeningDate = () => fields?.[RentalPeriodShoppingMallInputFields.OPENING_DATE]?.value
  const GetRentalPaymentStartDate = () => fields?.[RentalPeriodShoppingMallInputFields.RENTAL_PAYMENT_START]?.value
  const GetHasExtensionOptions = () => fields?.[RentalPeriodShoppingMallInputFields.HAS_EXTENSION_OPTIONS]?.value
  const GetExtensionType = () => fields?.[RentalPeriodShoppingMallInputFields.EXTENSION_TYPE]?.value
  const GetExtensionYears = () => fields?.[RentalPeriodShoppingMallInputFields.NUMBER_OF_YEARS]?.value

  // const GetExtensionYearsText = () => RenewalPeriod[GetExtensionYears()]
  const GetExtensionYearsText = () => GetExtensionYears()

  return <div>
    <h2>3. {i18n.t(`contract.general.rental_period`)}</h2>

    <div className={`${styles.cardSection}`}>
      <div className={`${styles.row}`}>
        <DateField
          extraClassNames="flex-grow"
          label={i18n.t(`contract.labels.${RentalPeriodShoppingMallInputFields.RENTAL_START}`)}
          name={RentalPeriodShoppingMallInputFields.RENTAL_START}
          required={true}
          value={GetRentalStartDate()}
          onChange={value => {
            console.log(`Date changed`, value)
            setMiscChanges.set({
              value
            }, {
              name: RentalPeriodShoppingMallInputFields.RENTAL_START
            })
          }}
        />
        <DateField
          extraClassNames="flex-grow"
          label={i18n.t(`contract.labels.${RentalPeriodShoppingMallInputFields.RENTAL_END}`)}
          name={RentalPeriodShoppingMallInputFields.RENTAL_END}
          required={true}
          value={GetRentalEndDate()}
          onChange={value => {
            console.log(`Date changed`, value)
            setMiscChanges.set({
              value
            }, {
              name: RentalPeriodShoppingMallInputFields.RENTAL_END
            })
          }}
        />
        <DateField
          extraClassNames="flex-grow"
          label={i18n.t(`contract.labels.${RentalPeriodShoppingMallInputFields.OPENING_DATE}`)}
          name={RentalPeriodShoppingMallInputFields.OPENING_DATE}
          required={true}
          value={GetOpeningDate()}
          onChange={value => {
            console.log(`Date changed`, value)
            setMiscChanges.set({
              value
            }, {
              name: RentalPeriodShoppingMallInputFields.OPENING_DATE
            })
          }}
        />
        <DateField
          extraClassNames="flex-grow"
          label={i18n.t(`contract.labels.${RentalPeriodShoppingMallInputFields.RENTAL_PAYMENT_START}`)}
          name={RentalPeriodShoppingMallInputFields.RENTAL_PAYMENT_START}
          required={true}
          value={GetRentalPaymentStartDate()}
          onChange={value => {
            console.log(`Date changed`, value)
            setMiscChanges.set({
              value
            }, {
              name: RentalPeriodShoppingMallInputFields.RENTAL_PAYMENT_START
            })
          }}
        />
      </div>
      <div className={`${styles.toggleHeader}`}>
        <div className="sub-clause-header">{i18n.t('contract.general.rental_period_potential_options')}</div>
        <Switch
          key={RentalPeriodShoppingMallInputFields.HAS_EXTENSION_OPTIONS}
          className={`secondary-switch-${!!GetHasExtensionOptions() ? 'checked' : 'unchecked'}`}
          isChecked={GetHasExtensionOptions()}
          isDisabled={false}
          onClick={_ => {
            setMiscChanges.set(
              { value: !GetHasExtensionOptions() },
              { name: RentalPeriodShoppingMallInputFields.HAS_EXTENSION_OPTIONS }
            )
          }}
        />
      </div>

      <VisibleIf predicate={!!GetHasExtensionOptions()}>
        <div className="flex flex-row items-center">
          <div>{i18n.t(`contract.labels.${RentalPeriodShoppingMallInputFields.HAS_EXTENSION_OPTIONS}`)}</div>
        </div>

        <div className="flex flex-col gap-4">
          <RadioButtonGroup selectedValue={GetExtensionType()} className="flex w-full gap-8 items-center">
            <RadioButton
              key={v4()}
              value={RentalPeriodExtensionType.EXTENSION}
              isDisabled={false}
              onClick={() => {
                setMiscChanges.set({
                  value: RentalPeriodExtensionType.EXTENSION
                }, {
                  name: RentalPeriodShoppingMallInputFields.EXTENSION_TYPE
                })
              }}
            >
              {i18n.t(`contract.fields.${RentalPeriodShoppingMallInputFields.EXTENSION_TYPE}.${RentalPeriodExtensionType.EXTENSION}`)}
            </RadioButton>
            <RadioButton
              key={v4()}
              value={RentalPeriodExtensionType.RENEWAL}
              isDisabled={false}
              onClick={() => {
                setMiscChanges.set({
                  value: RentalPeriodExtensionType.RENEWAL
                }, {
                  name: RentalPeriodShoppingMallInputFields.EXTENSION_TYPE
                })
              }}
            >
              {i18n.t(`contract.fields.${RentalPeriodShoppingMallInputFields.EXTENSION_TYPE}.${RentalPeriodExtensionType.RENEWAL}`)}
            </RadioButton>

            <VisibleIfInvalidField name={RentalPeriodShoppingMallInputFields.EXTENSION_TYPE}>
              <div className="text-pink-600 text-xs mt-1">
                {i18n.t('errors.rental_period_extension_type_not_selected')}
              </div>
            </VisibleIfInvalidField>
          </RadioButtonGroup>

          <div className="w-1/2">
            <Dropdown
              menu={{
                items: RenewalPeriodDropdownItemsFactory({
                  onClick: key => {
                    setMiscChanges.set({ value: key }, { name: RentalPeriodShoppingMallInputFields.NUMBER_OF_YEARS })
                  }
                })
              }}
              placement="bottom"
              trigger={['click']}
            >
              <div className="w-full">
                <DropdownField
                  label={i18n.t(`contract.labels.${RentalPeriodShoppingMallInputFields.NUMBER_OF_YEARS}`)}
                  value={GetExtensionYearsText()}
                />
              </div>
            </Dropdown>
          </div>
        </div>
        <VisibleIf predicate={GetExtensionType() === RentalPeriodExtensionType.EXTENSION}>
          <p>
            {
              i18n.t('contract.general.rental_period_extension_description', {
                years: GetExtensionYearsText()
              })
            }
          </p>
        </VisibleIf>
        <VisibleIf predicate={GetExtensionType() === RentalPeriodExtensionType.RENEWAL}>
          <p>
            {
              i18n.t('contract.general.rental_period_renewal_description', {
                years: GetExtensionYearsText()
              })
            }
          </p>
        </VisibleIf>
      </VisibleIf>

    </div>
  </div>
}

const RentalPeriodShoppingMallReadOnly: view = ({
  fields = observe.contract.originalv2.fields
}) => {
  const GetRentalStartDate = () => fields?.[RentalPeriodShoppingMallInputFields.RENTAL_START]?.value
  const GetRentalEndDate = () => fields?.[RentalPeriodShoppingMallInputFields.RENTAL_END]?.value
  const GetOpeningDate = () => fields?.[RentalPeriodShoppingMallInputFields.OPENING_DATE]?.value
  const GetRentalPaymentStartDate = () => fields?.[RentalPeriodShoppingMallInputFields.RENTAL_PAYMENT_START]?.value
  const GetHasExtensionOptions = () => fields?.[RentalPeriodShoppingMallInputFields.HAS_EXTENSION_OPTIONS]?.value
  const GetExtensionType = () => fields?.[RentalPeriodShoppingMallInputFields.EXTENSION_TYPE]?.value
  const GetExtensionYears = () => fields?.[RentalPeriodShoppingMallInputFields.NUMBER_OF_YEARS]?.value

  // const GetExtensionYearsText = () => RenewalPeriod[GetExtensionYears()]
  const GetExtensionYearsText = () => GetExtensionYears()

  return <div>
    <h2>3. {i18n.t(`contract.general.rental_period`)}</h2>

    <div className={`${styles.cardSection}`}>
      <div className={`${styles.row}`}>
        <DateFieldReadOnly
          label={i18n.t(`contract.labels.${RentalPeriodShoppingMallInputFields.RENTAL_START}`)}
          value={GetRentalStartDate()}
        />
        <DateFieldReadOnly
          label={i18n.t(`contract.labels.${RentalPeriodShoppingMallInputFields.RENTAL_END}`)}
          value={GetRentalEndDate()}
        />
        <DateFieldReadOnly
          label={i18n.t(`contract.labels.${RentalPeriodShoppingMallInputFields.OPENING_DATE}`)}
          value={GetOpeningDate()}
        />
        <DateFieldReadOnly
          label={i18n.t(`contract.labels.${RentalPeriodShoppingMallInputFields.OPENING_DATE}`)}
          value={GetRentalPaymentStartDate()}
        />
      </div>

      <div className={`${styles.toggleHeader}`}>
        <div className="sub-clause-header">Has extension/renewal options</div>
        <Switch
          key={RentalPeriodShoppingMallInputFields.HAS_EXTENSION_OPTIONS}
          className={`secondary-switch-${!!GetHasExtensionOptions() ? 'checked' : 'unchecked'}`}
          isChecked={GetHasExtensionOptions()}
          isDisabled={true}
        />
      </div>

      <VisibleIf predicate={!!GetHasExtensionOptions()}>
        <div className="flex flex-row items-center">
          <div>{i18n.t(`contract.labels.${RentalPeriodShoppingMallInputFields.HAS_EXTENSION_OPTIONS}`)}</div>
        </div>

        <div className="flex flex-col gap-4">
          <RadioButtonGroup selectedValue={GetExtensionType()} className="flex w-full gap-8">
            <RadioButton
              key={v4()}
              value={RentalPeriodExtensionType.EXTENSION}
              isDisabled={true}
            >
              {i18n.t(`contract.fields.${RentalPeriodShoppingMallInputFields.EXTENSION_TYPE}.${RentalPeriodExtensionType.EXTENSION}`)}
            </RadioButton>
            <RadioButton
              key={v4()}
              value={RentalPeriodExtensionType.RENEWAL}
              isDisabled={true}
            >
              {i18n.t(`contract.fields.${RentalPeriodShoppingMallInputFields.EXTENSION_TYPE}.${RentalPeriodExtensionType.RENEWAL}`)}
            </RadioButton>
          </RadioButtonGroup>

          <div className="w-1/2">
            <TextFieldReadOnly
              label={i18n.t(`contract.labels.${RentalPeriodShoppingMallInputFields.NUMBER_OF_YEARS}`)}
              value={GetExtensionYearsText()}
            />
          </div>
        </div>
      </VisibleIf>
    </div>
  </div>
}
