import { RadioButton, RadioButtonGroup, Spinner } from '@c11/components'
import { DatePicker } from 'antd'
import { TextEditor, TextEditorReadOnly } from 'src/components'
import { DateField } from 'src/components_generic/DateField'
import { NumberField, NumberFieldReadOnly } from 'src/components_generic/NumberField'
import { TextField, TextFieldReadOnly } from 'src/components_generic/TextField'
import { VisibleIf } from 'src/components_generic/visible-if'
import i18n from 'src/producers/languages/i18n'
import { v4 } from 'uuid'
import FormatNumber from 'format-number'
import styles from '../../../../../styles.module.css'
import { ReactNumberInput } from 'src/components_generic/ReactNumberInput'
import { NumberFormatterFactory } from 'src/components_generic/number-formatter'
import { VisibleIfInvalidField } from 'src/pages/ContractV2/components/VisibleIfInvalidField'

const NumberFormatter = NumberFormatterFactory()

export const RentalCosts: view = ({
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status
}) => {
  const isActiveContract = contractStatus === 'active'
  const displayReadOnlyView = isActiveContract || isLocked || true

  return <>
    <VisibleIf predicate={!displayReadOnlyView}>
      <RentalCostsParkingComponent/>
    </VisibleIf>
    <VisibleIf predicate={displayReadOnlyView}>
      <RentalCostsParkingComponentReadOnly/>
    </VisibleIf>
  </>
}

enum RentalCostsParkingInputFields {
  RENT_PER_PARKING_SPACE = 'rentPerParkingSpace',
  RENT_PER_CHARGER = 'rentPerCharger',
  DESCRIPTION = 'rentalCostDescription',
  COMMON_COSTS = 'commonCosts',
  FIRST_TIME_REGULATION = 'firstTimeRegulation',
  BASIC_INDEX = 'indexAdjustmentBasicIndex',
  PAYMENT_PROVISION = 'paymentProvisionType',
  VAT_EXEMPTED = 'isVATExempted'
}

enum CommonCosts {
  NO_COMMON_COSTS = 'no-common-costs',
  COMMON_CHARGES_ADDED = 'common-charges-will-be-added'
}

enum PaymentProvisionType {
  QUARTERLY_IN_ADVANCE = 'quarterly',
  MONTHLY_IN_ADVANCE = 'monthly'
}

const RentalCostsParkingComponent: view = ({
  fields = observe.contract.originalv2.fields,
  setChanges = update.contract.changes.fields[param.name],
  setMiscChanges = update.contract.changes.miscellaneous[param.name]
}) => {
  // if (!contract?.metadata?.readOnly) return null

  return <div>
    <h2>4. {i18n.t(`contract.general.rental_costs`)}</h2>
    <div className={`${styles.cardSectionColumn}`}>
      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">4.1 {i18n.t(`contract.general.rental_costs_subclause`)}</div>
        </div>

        <div className="flex flex-row items-center">
          <div className="flex-grow">
            <TextEditor
              readOnly={false}
              value={fields?.[RentalCostsParkingInputFields.DESCRIPTION]?.value}
              onChange={(e: any) => {
                setChanges.set({
                  value: e
                }, {
                  name: RentalCostsParkingInputFields.DESCRIPTION
                })
              }}
            />
          </div>
        </div>

        <div className={`${styles.row}`}>
          <ReactNumberInput
            label={i18n.t(`contract.labels.${RentalCostsParkingInputFields.RENT_PER_PARKING_SPACE}`)}
            name={RentalCostsParkingInputFields.RENT_PER_PARKING_SPACE}
            required={true}
            value={(fields?.[RentalCostsParkingInputFields.RENT_PER_PARKING_SPACE]?.value || null) as number}
            placeholder={i18n.t(`contract.placeholders.${RentalCostsParkingInputFields.RENT_PER_PARKING_SPACE}`)}
            onChange={value => setChanges.set({ value }, {
              name: RentalCostsParkingInputFields.RENT_PER_PARKING_SPACE
            })}
          />
          <ReactNumberInput
            label={i18n.t(`contract.labels.${RentalCostsParkingInputFields.RENT_PER_CHARGER}`)}
            name={RentalCostsParkingInputFields.RENT_PER_CHARGER}
            required={true}
            value={(fields?.[RentalCostsParkingInputFields.RENT_PER_CHARGER]?.value || null) as number}
            placeholder={i18n.t(`contract.placeholders.${RentalCostsParkingInputFields.RENT_PER_CHARGER}`)}
            onChange={value => setChanges.set({ value }, {
              name: RentalCostsParkingInputFields.RENT_PER_CHARGER
            })}
          />
        </div>
      </div>

      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">4.2 {i18n.t(`contract.general.common_costs`)}</div>
        </div>

        <div className="flex flex-row items-center">
          <RadioButtonGroup selectedValue={fields[RentalCostsParkingInputFields.COMMON_COSTS]?.value}
                            className="flex flex-row gap-8">
            <RadioButton
              key={v4()}
              value={CommonCosts.NO_COMMON_COSTS}
              isDisabled={false}
              onClick={() => {
                setMiscChanges.set({
                  value: CommonCosts.NO_COMMON_COSTS
                }, {
                  name: RentalCostsParkingInputFields.COMMON_COSTS
                })
              }}
            >
              {i18n.t(`commonCosts.${CommonCosts.NO_COMMON_COSTS}`)}
            </RadioButton>
            <RadioButton
              key={v4()}
              value={CommonCosts.COMMON_CHARGES_ADDED}
              isDisabled={false}
              onClick={() => {
                setMiscChanges.set({
                  value: CommonCosts.COMMON_CHARGES_ADDED
                }, {
                  name: RentalCostsParkingInputFields.COMMON_COSTS
                })
              }}
            >
              {i18n.t(`commonCosts.${CommonCosts.COMMON_CHARGES_ADDED}`)}
            </RadioButton>

            <VisibleIfInvalidField name={RentalCostsParkingInputFields.COMMON_COSTS}>
              <div className="text-pink-600 text-xs mt-1">
                {i18n.t('errors.common_costs_not_selected')}
              </div>
            </VisibleIfInvalidField>
          </RadioButtonGroup>
        </div>
      </div>

      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">4.3 {i18n.t(`contract.general.index_regulation`)}</div>
        </div>

        <div className="flex flex-row items-center">
          {i18n.t(`clause_rental_costs_index_adjustment_first_time_regulation`)}
        </div>

        <div className={`${styles.row}`}>
          <DateField
            name={RentalCostsParkingInputFields.FIRST_TIME_REGULATION}
            label={i18n.t(`contract.labels.${RentalCostsParkingInputFields.FIRST_TIME_REGULATION}`)}
            value={fields[RentalCostsParkingInputFields.FIRST_TIME_REGULATION]?.value}
            onChange={value => {
              setMiscChanges.set({
                value
              }, {
                name: RentalCostsParkingInputFields.FIRST_TIME_REGULATION
              })
            }}
          />
          <DateField
            name={RentalCostsParkingInputFields.BASIC_INDEX}
            label={i18n.t(`contract.labels.${RentalCostsParkingInputFields.BASIC_INDEX}`)}
            value={fields[RentalCostsParkingInputFields.BASIC_INDEX]?.value}
            onChange={value => {
              setMiscChanges.set({
                value
              }, {
                name: RentalCostsParkingInputFields.BASIC_INDEX
              })
            }}
          />
        </div>
      </div>

      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">4.4 {i18n.t(`contract.general.payment_provision`)}</div>
        </div>

        <div className="flex flex-row items-center gap-8">
          <RadioButtonGroup selectedValue={fields[RentalCostsParkingInputFields.PAYMENT_PROVISION]?.value}
                            className="flex flex-row gap-8">
            <RadioButton
              key={v4()}
              value={PaymentProvisionType.QUARTERLY_IN_ADVANCE}
              isDisabled={false}
              onClick={() => {
                setMiscChanges.set({
                  value: PaymentProvisionType.QUARTERLY_IN_ADVANCE
                }, {
                  name: RentalCostsParkingInputFields.PAYMENT_PROVISION
                })
              }}
            >
              {i18n.t(`contract.fields.paymentProvisionType.${PaymentProvisionType.QUARTERLY_IN_ADVANCE}`)}
            </RadioButton>
            <RadioButton
              key={v4()}
              value={PaymentProvisionType.MONTHLY_IN_ADVANCE}
              isDisabled={false}
              onClick={() => {
                setMiscChanges.set({
                  value: PaymentProvisionType.MONTHLY_IN_ADVANCE
                }, {
                  name: RentalCostsParkingInputFields.PAYMENT_PROVISION
                })
              }}
            >
              {i18n.t(`contract.fields.paymentProvisionType.${PaymentProvisionType.MONTHLY_IN_ADVANCE}`)}
            </RadioButton>

            <VisibleIfInvalidField name={RentalCostsParkingInputFields.PAYMENT_PROVISION}>
              <div className="text-pink-600 text-xs mt-1">
                {i18n.t("errors.payment_provision_type_not_selected")}
              </div>
            </VisibleIfInvalidField>
          </RadioButtonGroup>
        </div>
      </div>

      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">4.5 {i18n.t(`contract.general.VAT_exemption`)}</div>
        </div>

        <div className="flex flex-row items-center gap-8">
          <RadioButtonGroup selectedValue={fields[RentalCostsParkingInputFields.VAT_EXEMPTED]?.value}
                            className="flex flex-row gap-8">
            <RadioButton
              key={v4()}
              value={'yes'}
              isDisabled={false}
              onClick={() => {
                setMiscChanges.set({
                  value: 'yes'
                }, {
                  name: RentalCostsParkingInputFields.VAT_EXEMPTED
                })
              }}
            >
              {i18n.t('contract.fields.vatExempted.yes')}
            </RadioButton>
            <RadioButton
              key={v4()}
              value={'no'}
              isDisabled={false}
              onClick={() => {
                setMiscChanges.set({
                  value: 'no'
                }, {
                  name: RentalCostsParkingInputFields.VAT_EXEMPTED
                })
              }}
            >
              {i18n.t('contract.fields.vatExempted.no')}
            </RadioButton>

            <VisibleIfInvalidField name={RentalCostsParkingInputFields.VAT_EXEMPTED}>
              <div className="text-pink-600 text-xs mt-1">
                {i18n.t('errors.vat_exemption_not_selected')}
              </div>
            </VisibleIfInvalidField>
          </RadioButtonGroup>
        </div>
      </div>
    </div>
  </div>
}

const RentalCostsParkingComponentReadOnly: view = ({
  contract = observe.contract.originalv2,
  fields = observe.contract.originalv2.fields
}) => {

  return <div>
    <h2>{i18n.t(`contract.general.rental_costs`)}</h2>
    <div className={`${styles.cardSectionColumn}`}>
      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header"></div>
        </div>

        <TextEditorReadOnly
          readOnly={true}
          value={fields?.[RentalCostsParkingInputFields.DESCRIPTION]?.value}
        />

        <div className={`${styles.row}`}>
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${RentalCostsParkingInputFields.RENT_PER_PARKING_SPACE}`)}
            value={
              NumberFormatter(fields?.[RentalCostsParkingInputFields.RENT_PER_PARKING_SPACE]?.value)
            }
          />
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${RentalCostsParkingInputFields.RENT_PER_CHARGER}`)}
            value={
              NumberFormatter(fields?.[RentalCostsParkingInputFields.RENT_PER_CHARGER]?.value)
            }
          />
        </div>
      </div>

      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">{i18n.t(`contract.general.common_costs`)}</div>
        </div>

        <div className="flex flex-row items-center">
          <RadioButtonGroup selectedValue={fields[RentalCostsParkingInputFields.COMMON_COSTS]?.value}
                            className="flex flex-row gap-8">
            <RadioButton
              key={v4()}
              value={CommonCosts.NO_COMMON_COSTS}
              isDisabled
            >
              {i18n.t(`commonCosts.${CommonCosts.NO_COMMON_COSTS}`)}
            </RadioButton>
            <RadioButton
              key={v4()}
              value={CommonCosts.COMMON_CHARGES_ADDED}
              isDisabled
            >
              {i18n.t(`commonCosts.${CommonCosts.COMMON_CHARGES_ADDED}`)}
            </RadioButton>
          </RadioButtonGroup>
        </div>
      </div>

      <div className={`${styles.cardSection}`}>
        <div className="flex flex-row items-center">
          <div className="sub-clause-header">{i18n.t(`contract.general.VAT_exemption`)}</div>
        </div>

        <div className="flex flex-row items-center gap-8">
          <RadioButtonGroup selectedValue={fields[RentalCostsParkingInputFields.VAT_EXEMPTED]?.value}
                            className="flex flex-row gap-8">
            <RadioButton
              key={v4()}
              value={'yes'}
              isDisabled
            >
              {i18n.t('contract.fields.vatExempted.yes')}
            </RadioButton>
            <RadioButton
              key={v4()}
              value={'no'}
              isDisabled
            >
              {i18n.t('contract.fields.vatExempted.no')}
            </RadioButton>
          </RadioButtonGroup>
        </div>
      </div>
    </div>
  </div>
}
