import { Modal } from "src/components";

export const RemovePartyModalWindow: view = ({
  removePartyModal = observe.modal.removePartyModal,
  updateRemovePartyModal = update.modal.removePartyModal,

  updateRemovedParty = update.contract.changes.parties.removed
}) => {
  if (!removePartyModal?.isOpen) return null;
  if (!removePartyModal?.partyId) return null;

  return (
    <Modal
      title={"Remove participant"}
      okButtonText={"OK"}
      isDisabled={true}
      onOkClick={() => {
        updateRemovedParty.set({
          id: removePartyModal.partyId
        })

        updateRemovePartyModal.remove();
      }}
      width={600}
    >
      Are you sure that you want to delete the participant ?
    </Modal>
  );
};
