import copy from "copy-to-clipboard";
import { Menu, Dropdown, notification } from "antd";
import { ThreeDots } from "src/assets/svg";
import "src/pages/Dashboard/ContractMenu/ContractMenu.css";
import i18n from "src/producers/languages/i18n";
import { ContractFilterOption, UserRole } from "src/types";
import styles from "../styles.module.css"

export const ContractMenu: view = ({
  contractId,
  contractFilterFlag,
  userRole = observe.user.data.role,
  setFilterFlag = update.pages.dashboard.triggers.setFilterFlag,
  removeFilterTag = update.pages.dashboard.triggers.removeFilterTag,
}) => {
  const isManager = userRole === UserRole.MANAGER;
  const menu = (
    <Menu>
      {isManager &&
        (contractFilterFlag === ContractFilterOption.ARCHIVED ? (
          <Menu.Item
            className="mx-2 my-1"
            key="2"
            disabled={true}
            onClick={(param) => {
              param.domEvent.stopPropagation();
              removeFilterTag.set({ contractId });
            }}>
            {i18n.t("dashboard.menu_li_unarchive")}
          </Menu.Item>
        ) : (
          <Menu.Item
            className="mx-2 my-1"
            key="1"
            disabled={true}
            onClick={(param) => {
              param.domEvent.stopPropagation();
              setFilterFlag.set({ contractId, tag: ContractFilterOption.ARCHIVED });
            }}>
            {i18n.t("dashboard.menu_li_archive")}
          </Menu.Item>
        ))}

      {isManager &&
        (contractFilterFlag === ContractFilterOption.LOST ? (
          <Menu.Item
            className="mx-2 my-1"
            key="3"
            disabled={true}
            onClick={(param) => {
              param.domEvent.stopPropagation();
              removeFilterTag.set({ contractId });
            }}>
            {i18n.t("dashboard.menu_li_unlost")}
          </Menu.Item>
        ) : (
          <Menu.Item
            className="mx-2 my-1"
            key="4"
            disabled={true}
            onClick={(param) => {
              param.domEvent.stopPropagation();
              setFilterFlag.set({ contractId, tag: ContractFilterOption.LOST });
            }}>
            {i18n.t("dashboard.menu_li_lost")}
          </Menu.Item>
        ))}

      {isManager &&
        (contractFilterFlag === ContractFilterOption.TRASHED ? (
          <Menu.Item
            className="mx-2 my-1"
            key="5"
            disabled={true}
            onClick={(param) => {
              param.domEvent.stopPropagation();
              removeFilterTag.set({ contractId });
            }}>
            {i18n.t("dashboard.menu_li_untrash")}
          </Menu.Item>
        ) : (
          <Menu.Item
            className="mx-2 my-1"
            key="6"
            disabled={true}
            onClick={(param) => {
              param.domEvent.stopPropagation();
              setFilterFlag.set({ contractId, tag: ContractFilterOption.TRASHED });
            }}>
            {i18n.t("dashboard.menu_li_trash")}
          </Menu.Item>
        ))}
      <Menu.Item
        className="mx-2 my-1"
        key="8"
        onClick={(param) => {
          param.domEvent.stopPropagation();
          copy(`${window.location.host}/contract/${contractId}?autologin=true`);
          notification.success({
            message: i18n.t("dashboard.notification_contract_link_copy"),
          });
        }}>
        {i18n.t("dashboard.menu_li_copy")}
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} placement="bottomLeft" trigger={["click"]}>
       <div className={`${styles.contractMenu}`} onClick={(e: any) => e.stopPropagation()}>
        <ThreeDots  />
      </div>
    </Dropdown>
  );
};
