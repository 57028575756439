import { Button } from '@c11/components'
import { Dropdown, MenuProps } from 'antd'
import { Clause } from 'src/components_generic/Clause'
import { DropdownField } from 'src/components_generic/DropdownField'
import { NumberField, NumberFieldReadOnly } from 'src/components_generic/NumberField'
import { TextField, TextFieldReadOnly } from 'src/components_generic/TextField'
import { VisibleIf } from 'src/components_generic/visible-if'
import i18n from 'src/producers/languages/i18n'
import styles from '../../../styles.module.css'
import { ReactNumberInput, ReactPatternInput } from 'src/components_generic/ReactNumberInput'
import { SearchTenant } from './SearchTenant'

export const PartiesClause: view = ({
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status
}) => {
  const isActiveContract = contractStatus === 'active'
  const displayReadOnlyView = isActiveContract || isLocked || true

  return <>
    <VisibleIf predicate={!displayReadOnlyView}>
      <PartiesClauseComponent/>
    </VisibleIf>
    <VisibleIf predicate={displayReadOnlyView}>
      <PartiesClauseComponentReadOnly/>
    </VisibleIf>
  </>
}

export enum LandlordInputFields {
  NAME = 'landlordName',
  ORGANIZATION_NUMBER = 'landlordOrgNo',
  ADDRESS = 'landlordAddress',
  POSTAL_ADDRESS = 'landlordPostalAddress',
  INVOICE_ADDRESS = 'landlordInvoiceAddress',
  ACCOUNT_NUMBER = 'landlordAccountNo',
  PROPERTY_MANAGER = 'landlordPropertyManager',
  CONTACT_PERSON_FIRST_NAME = 'landlordContactPersonFirstName',
  CONTACT_PERSON_LAST_NAME = 'landlordContactPersonLastName',
  PHONE_NUMBER = 'landlordPhone',
  EMAIL = 'landlordEmail',
}

export enum TenantInputFields {
  NUMBER = 'tenantID',
  NAME = 'tenantName',
  ORGANIZATION_NUMBER = 'tenantOrgNo',
  ADDRESS = 'tenantAddress',
  POSTAL_ADDRESS = 'tenantPostalAddress',
  INVOICE_ADDRESS = 'tenantInvoiceAddress',

  CONTACT_PERSON_FIRST_NAME = 'tenantContactPersonFirstName',
  CONTACT_PERSON_LAST_NAME = 'tenantContactPersonLastName',
  CONTACT_PERSON_PHONE_NUMBER = 'tenantPhone',
  CONTACT_PERSON_EMAIL = 'tenantEmail'
}


const PartiesClauseComponent: view = ({
  tenant = observe.contract.originalv2.tenant,
  selectedTenantContact = observe.contract.originalv2View.selectedTenantContact,
  fields = observe.contract.originalv2.fields,
  tenantIDTemp = observe.contract.originalv2View.tenantIDTemp,
  tenantIDQuery = observe.contract.originalv2View.tenantIDQuery,
  selectedContact = get.contract.originalv2.tenant.selectedContact,
  setChanges = update.contract.changes.fields[param.name],
  updateTenantIDTemp = update.contract.originalv2View.tenantIDTemp,
  updateTenantIDQuery = update.contract.originalv2View.tenantIDQuery,
  updateSelectedTenantContact = update.contract.originalv2View.selectedTenantContact,
  tenantDropdownFlag = observe.featureFlags.tenantsDropdown.main
}) => {
  // if (!contract?.metadata?.readOnly) return null
  const GetTenantID = () => {
    return tenant?.number
      || tenantIDQuery
      || tenantIDTemp
      || void (0)
  }

  const GetTenantContactByID = (id: string) => {
    return tenant.mainContacts?.find((contact: any) => contact.id === id)
  }

  const GetTenantContact = () => {
    if (!tenant) return null
    if (selectedContact.value()) return selectedContact.value()
    // if (tenant.mainContacts.length < 2) return tenant.mainContacts[0];
    // if (!selectedTenantContact) return tenant.mainContacts[0];

    return GetTenantContactByID(selectedTenantContact)
  }

  const GetTenantContactFirstName = () => {
    return GetTenantContact()?.firstName
      || fields[TenantInputFields.CONTACT_PERSON_FIRST_NAME]?.value
  }
  const GetTenantContractLastName = () => {
    return GetTenantContact()?.lastName
      || fields[TenantInputFields.CONTACT_PERSON_LAST_NAME]?.value
  }
  const GetTenantContractEmail = () => {
    return GetTenantContact()?.email
      || fields[TenantInputFields.CONTACT_PERSON_EMAIL]?.value
  }
  const GetTenantContractPhone = () => {
    return GetTenantContact()?.phone
      || fields[TenantInputFields.CONTACT_PERSON_PHONE_NUMBER]?.value
  }
  const GetTenantContractDropdownFieldValue = () => {
    const tenantFirstName = GetTenantContactFirstName()
    const tenantLastName = GetTenantContractLastName()
    const tenantEmail = GetTenantContractEmail()
    const selectedTenantContact = `${tenantFirstName} ${tenantLastName} ${tenantEmail}`
    if (!tenantFirstName && !tenantLastName && !tenantEmail) return i18n.t('contract.general.selectContact')
    return selectedTenantContact
  }

  return <div>
    <h2>1. {i18n.t('contract.general.parties')}</h2>
    <div className={`${styles.cardSectionColumn}`}>
      <Clause index="1.1" title={i18n.t('contract.general.landlord')}>
        <div className={`${styles.row}`}>
          <TextField
            label={i18n.t(`contract.labels.${LandlordInputFields.NAME}`)}
            name={LandlordInputFields.NAME}
            required={true}
            value={(fields?.[LandlordInputFields.NAME]?.value || '') as string}
            placeholder={i18n.t(`contract.placeholders.${LandlordInputFields.NAME}`)}
            onChange={value => setChanges.set({ value }, {
              name: LandlordInputFields.NAME
            })}
          />

          <TextField
            // decimalScale={0}
            // thousandSeparator=""
            label={i18n.t(`contract.labels.${LandlordInputFields.ORGANIZATION_NUMBER}`)}
            name={LandlordInputFields.ORGANIZATION_NUMBER}
            required={true}
            value={(fields?.[LandlordInputFields.ORGANIZATION_NUMBER]?.value || '') as string}
            placeholder={i18n.t(`contract.placeholders.${LandlordInputFields.ORGANIZATION_NUMBER}`)}
            onChange={value => setChanges.set({ value }, {
              name: LandlordInputFields.ORGANIZATION_NUMBER
            })}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextField
            label={i18n.t(`contract.labels.${LandlordInputFields.ADDRESS}`)}
            name={LandlordInputFields.ADDRESS}
            required={true}
            value={(fields?.[LandlordInputFields.ADDRESS]?.value || '') as string}
            placeholder={i18n.t(`contract.placeholders.${LandlordInputFields.ADDRESS}`)}
            onChange={value => setChanges.set({ value }, {
              name: LandlordInputFields.ADDRESS
            })}
          />

          <TextField
            label={i18n.t(`contract.labels.${LandlordInputFields.POSTAL_ADDRESS}`)}
            name={LandlordInputFields.POSTAL_ADDRESS}
            required={true}
            value={(fields?.[LandlordInputFields.POSTAL_ADDRESS]?.value || '') as string}
            placeholder={i18n.t(`contract.placeholders.${LandlordInputFields.POSTAL_ADDRESS}`)}
            onChange={value => setChanges.set({ value }, {
              name: LandlordInputFields.POSTAL_ADDRESS
            })}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextField
            label={i18n.t(`contract.labels.${LandlordInputFields.INVOICE_ADDRESS}`)}
            name={LandlordInputFields.INVOICE_ADDRESS}
            required={true}
            value={(fields?.[LandlordInputFields.INVOICE_ADDRESS]?.value || '') as string}
            placeholder={i18n.t(`contract.placeholders.${LandlordInputFields.INVOICE_ADDRESS}`)}
            onChange={value => setChanges.set({ value }, {
              name: LandlordInputFields.INVOICE_ADDRESS
            })}
          />

          <TextField
            // decimalScale={0}
            // thousandSeparator=""
            label={i18n.t(`contract.labels.${LandlordInputFields.ACCOUNT_NUMBER}`)}
            name={LandlordInputFields.ACCOUNT_NUMBER}
            required={true}
            value={(fields?.[LandlordInputFields.ACCOUNT_NUMBER]?.value || '') as string}
            placeholder={i18n.t(`contract.placeholders.${LandlordInputFields.ACCOUNT_NUMBER}`)}
            onChange={value => setChanges.set({ value }, {
              name: LandlordInputFields.ACCOUNT_NUMBER
            })}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextField
            label={i18n.t(`contract.labels.${LandlordInputFields.PROPERTY_MANAGER}`)}
            name={LandlordInputFields.PROPERTY_MANAGER}
            required={true}
            value={(fields?.[LandlordInputFields.PROPERTY_MANAGER]?.value || '') as string}
            placeholder={i18n.t(`contract.placeholders.${LandlordInputFields.PROPERTY_MANAGER}`)}
            onChange={value => setChanges.set({ value }, {
              name: LandlordInputFields.PROPERTY_MANAGER
            })}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextField
            label={i18n.t(`contract.labels.${LandlordInputFields.CONTACT_PERSON_FIRST_NAME}`)}
            name={LandlordInputFields.CONTACT_PERSON_FIRST_NAME}
            required={true}
            value={(fields?.[LandlordInputFields.CONTACT_PERSON_FIRST_NAME]?.value || '') as string}
            placeholder={i18n.t(`contract.placeholders.${LandlordInputFields.CONTACT_PERSON_FIRST_NAME}`)}
            onChange={value => setChanges.set({ value }, {
              name: LandlordInputFields.CONTACT_PERSON_FIRST_NAME
            })}
          />

          <TextField
            label={i18n.t(`contract.labels.${LandlordInputFields.CONTACT_PERSON_LAST_NAME}`)}
            name={LandlordInputFields.CONTACT_PERSON_LAST_NAME}
            required={true}
            value={(fields?.[LandlordInputFields.CONTACT_PERSON_LAST_NAME]?.value || '') as string}
            placeholder={i18n.t(`contract.placeholders.${LandlordInputFields.CONTACT_PERSON_LAST_NAME}`)}
            onChange={value => setChanges.set({ value }, {
              name: LandlordInputFields.CONTACT_PERSON_LAST_NAME
            })}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextField
            label={i18n.t(`contract.labels.${LandlordInputFields.PHONE_NUMBER}`)}
            name={LandlordInputFields.PHONE_NUMBER}
            value={(fields?.[LandlordInputFields.PHONE_NUMBER]?.value || '') as string}
            placeholder={i18n.t(`contract.placeholders.${LandlordInputFields.PHONE_NUMBER}`)}
            onChange={value => setChanges.set({ value }, {
              name: LandlordInputFields.PHONE_NUMBER
            })}
          />

          <TextField
            label={i18n.t(`contract.labels.${LandlordInputFields.EMAIL}`)}
            name={LandlordInputFields.EMAIL}
            required={true}
            value={(fields?.[LandlordInputFields.EMAIL]?.value || '') as string}
            placeholder={i18n.t(`contract.placeholders.${LandlordInputFields.EMAIL}`)}
            onChange={value => setChanges.set({ value }, {
              name: LandlordInputFields.EMAIL
            })}
          />
        </div>
      </Clause>

      <Clause index="1.2" title={i18n.t('contract.general.tenant')}>
       { !tenantDropdownFlag &&
        <div className={`${styles.row}`}>
            <div className="w-1/2">
              <ReactNumberInput
                decimalScale={0}
                thousandSeparator=""
                label={i18n.t(`contract.labels.${TenantInputFields.NUMBER}`)}
                name={TenantInputFields.NUMBER}
                required={true}
                placeholder={i18n.t('contract.placeholders.tenant_number')}
                value={GetTenantID()}
                onChange={value => {
                  updateTenantIDTemp.set(value)
                }}
                onValueCleared={() => {
                  updateTenantIDQuery.set(null)
                }}
              />
            </div>

            <div className="w-1/2">
              <Button
                className="uppercase mt-5"
                variant="primary"
                isDisabled={!tenantIDTemp && !tenantIDQuery}
                onClick={() => {
                  updateTenantIDQuery.set(tenantIDTemp)
                  updateTenantIDTemp.set(null)
                }}
              >
                {i18n.t('button_link_tenant')}
              </Button>
            </div>
          </div>
       }

        { tenantDropdownFlag &&
          <div className={`${styles.row}`}>
            <SearchTenant />
          </div>
        }


        <div className={`${styles.row}`}>
          <TextField
            label={i18n.t(`contract.labels.${TenantInputFields.NAME}`)}
            name={TenantInputFields.NAME}
            required={true}
            value={(fields?.[TenantInputFields.NAME]?.value || '') as string}
            placeholder={i18n.t(`contract.placeholders.${TenantInputFields.NAME}`)}
            onChange={value => setChanges.set({ value }, {
              name: TenantInputFields.NAME
            })}
          />
          <TextField
            // decimalScale={0}
            // thousandSeparator=""
            label={i18n.t(`contract.labels.${TenantInputFields.ORGANIZATION_NUMBER}`)}
            name={TenantInputFields.ORGANIZATION_NUMBER}
            required={true}
            value={(fields?.[TenantInputFields.ORGANIZATION_NUMBER]?.value || '') as string}
            placeholder={i18n.t(`contract.placeholders.${TenantInputFields.ORGANIZATION_NUMBER}`)}
            onChange={value => setChanges.set({ value }, {
              name: TenantInputFields.ORGANIZATION_NUMBER
            })}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextField
            label={i18n.t(`contract.labels.${TenantInputFields.ADDRESS}`)}
            name={TenantInputFields.ADDRESS}
            required={true}
            value={(fields?.[TenantInputFields.ADDRESS]?.value || '') as string}
            placeholder={i18n.t(`contract.placeholders.${TenantInputFields.ADDRESS}`)}
            onChange={value => setChanges.set({ value }, {
              name: TenantInputFields.ADDRESS
            })}
          />

          <TextField
            label={i18n.t(`contract.labels.${TenantInputFields.POSTAL_ADDRESS}`)}
            name={TenantInputFields.POSTAL_ADDRESS}
            required={true}
            value={(fields?.[TenantInputFields.POSTAL_ADDRESS]?.value || '') as string}
            placeholder={i18n.t(`contract.placeholders.${TenantInputFields.POSTAL_ADDRESS}`)}
            onChange={value => setChanges.set({ value }, {
              name: TenantInputFields.POSTAL_ADDRESS
            })}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextField
            label={i18n.t(`contract.labels.${TenantInputFields.INVOICE_ADDRESS}`)}
            name={TenantInputFields.INVOICE_ADDRESS}
            required={true}
            value={(fields?.[TenantInputFields.INVOICE_ADDRESS]?.value || '') as string}
            placeholder={i18n.t(`contract.placeholders.${TenantInputFields.INVOICE_ADDRESS}`)}
            onChange={value => setChanges.set({ value }, {
              name: TenantInputFields.INVOICE_ADDRESS
            })}
          />
        </div>

        <div className="sub-clause-header mt-4">{i18n.t(`contract.general.tenant_contact`)}</div>

        <VisibleIf predicate={tenant?.mainContacts?.length > 0}>
          <div className={`${styles.row}`}>
            <Dropdown
              menu={{
                items: PartiesDropdownMenu({
                  items: tenant?.mainContacts,
                  onClick: contactID => {
                    updateSelectedTenantContact.set(contactID)
                  }
                })
              }}
              placement="bottom"
              trigger={['click']}
            >
              <div className="w-full">
                <DropdownField
                  label={i18n.t(`contract.labels.tenantContactDropdown`)}
                  value={GetTenantContractDropdownFieldValue()}
                />
              </div>
            </Dropdown>
          </div>
        </VisibleIf>

        <div className={`${styles.row}`}>
          <TextField
            label={i18n.t(`contract.labels.${TenantInputFields.CONTACT_PERSON_FIRST_NAME}`)}
            name={TenantInputFields.CONTACT_PERSON_FIRST_NAME}
            required={true}
            value={GetTenantContactFirstName()}
            placeholder={i18n.t(`contract.placeholders.${TenantInputFields.CONTACT_PERSON_FIRST_NAME}`)}
            onChange={value => setChanges.set({ value }, {
              name: TenantInputFields.CONTACT_PERSON_FIRST_NAME
            })}
          />

          <TextField
            label={i18n.t(`contract.labels.${TenantInputFields.CONTACT_PERSON_LAST_NAME}`)}
            name={TenantInputFields.CONTACT_PERSON_LAST_NAME}
            required={true}
            value={GetTenantContractLastName()}
            placeholder={i18n.t(`contract.placeholders.${TenantInputFields.CONTACT_PERSON_LAST_NAME}`)}
            onChange={value => setChanges.set({ value }, {
              name: TenantInputFields.CONTACT_PERSON_LAST_NAME
            })}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextField
            label={i18n.t(`contract.labels.${TenantInputFields.CONTACT_PERSON_PHONE_NUMBER}`)}
            name={TenantInputFields.CONTACT_PERSON_PHONE_NUMBER}
            value={GetTenantContractPhone()}
            placeholder={i18n.t(`contract.placeholders.${TenantInputFields.CONTACT_PERSON_PHONE_NUMBER}`)}
            onChange={value => setChanges.set({ value }, {
              name: TenantInputFields.CONTACT_PERSON_PHONE_NUMBER
            })}
          />

          <TextField
            label={i18n.t(`contract.labels.${TenantInputFields.CONTACT_PERSON_EMAIL}`)}
            name={TenantInputFields.CONTACT_PERSON_EMAIL}
            required={true}
            value={GetTenantContractEmail()}
            placeholder={i18n.t(`contract.placeholders.${TenantInputFields.CONTACT_PERSON_EMAIL}`)}
            onChange={value => setChanges.set({ value }, {
              name: TenantInputFields.CONTACT_PERSON_EMAIL
            })}
          />
        </div>
        {/*</div>*/}
      </Clause>
    </div>
  </div>
}

const PartiesClauseComponentReadOnly: view = ({
  contract = observe.contract.originalv2,
  fields = observe.contract.originalv2.fields,
  tenant = observe.contract.originalv2.tenant,
  selectedTenantContact = observe.contract.originalv2View.selectedTenantContact,
  updateSelectedTenantContact = update.contract.originalv2View.selectedTenantContact
}) => {
  const GetTenantContactByID = (id: string) => {
    return tenant.mainContacts.find((contact: any) => contact.id === id)
  }

  const GetTenantContact = () => {
    if (!tenant) return null
    if (!tenant?.mainContacts) return null
    if (tenant.mainContacts?.length < 2) return tenant.mainContacts[0]
    // console.log('selectedTenantContact1111', selectedTenantContact)
    // if (!selectedTenantContact) return tenant.mainContacts[0]
    // console.log('selectedTenantContact', selectedTenantContact)
    if(selectedTenantContact) return GetTenantContactByID(selectedTenantContact)
  }

  const GetTenantContactFirstName = () => {
    return GetTenantContact()?.firstName
      || fields[TenantInputFields.CONTACT_PERSON_FIRST_NAME]?.value
  }
  const GetTenantContractLastName = () => {
    return GetTenantContact()?.lastName
      || fields[TenantInputFields.CONTACT_PERSON_LAST_NAME]?.value
  }
  const GetTenantContractEmail = () => {
    return GetTenantContact()?.email
      || fields[TenantInputFields.CONTACT_PERSON_EMAIL]?.value
  }
  const GetTenantContractPhone = () => {
    return GetTenantContact()?.phone
      || fields[TenantInputFields.CONTACT_PERSON_PHONE_NUMBER]?.value
  }
  const GetTenantContractDropdownFieldValue = () => {
    return `${GetTenantContactFirstName()} ${GetTenantContractLastName()} (${GetTenantContractEmail()})`
  }

  return <div>
    <h2>1. {i18n.t('contract.general.parties')}</h2>
    <div className={`${styles.cardSectionColumn}`}>
      <div className={`${styles.cardSection}`}>
        <div className="sub-clause-header">1.1 {i18n.t('contract.general.landlord')}</div>

        <div className={`${styles.row}`}>
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${LandlordInputFields.NAME}`)}
            required={true}
            value={(fields?.[LandlordInputFields.NAME]?.value || '') as string}
          />

          <NumberFieldReadOnly
            label={i18n.t(`contract.labels.${LandlordInputFields.ORGANIZATION_NUMBER}`)}
            required={true}
            value={(fields?.[LandlordInputFields.ORGANIZATION_NUMBER]?.value || null) as number}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${LandlordInputFields.ADDRESS}`)}
            required={true}
            value={(fields?.[LandlordInputFields.ADDRESS]?.value || '') as string}
          />

          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${LandlordInputFields.POSTAL_ADDRESS}`)}
            required={true}
            value={(fields?.[LandlordInputFields.POSTAL_ADDRESS]?.value || '') as string}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${LandlordInputFields.INVOICE_ADDRESS}`)}
            required={true}
            value={(fields?.[LandlordInputFields.INVOICE_ADDRESS]?.value || '') as string}
          />

          <NumberFieldReadOnly
            label={i18n.t(`contract.labels.${LandlordInputFields.ACCOUNT_NUMBER}`)}
            required={true}
            value={(fields?.[LandlordInputFields.ACCOUNT_NUMBER]?.value || '') as number}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${LandlordInputFields.PROPERTY_MANAGER}`)}
            required={true}
            value={(fields?.[LandlordInputFields.PROPERTY_MANAGER]?.value || '') as string}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${LandlordInputFields.CONTACT_PERSON_FIRST_NAME}`)}
            required={true}
            value={(fields?.[LandlordInputFields.CONTACT_PERSON_FIRST_NAME]?.value || '') as string}
          />

          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${LandlordInputFields.CONTACT_PERSON_LAST_NAME}`)}
            required={true}
            value={(fields?.[LandlordInputFields.CONTACT_PERSON_LAST_NAME]?.value || '') as string}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${LandlordInputFields.PHONE_NUMBER}`)}
            value={(fields?.[LandlordInputFields.PHONE_NUMBER]?.value || '') as string}
          />

          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${LandlordInputFields.EMAIL}`)}
            required={true}
            value={(fields?.[LandlordInputFields.EMAIL]?.value || '') as string}
          />
        </div>
      </div>

      <div className={styles.cardSection}>
        <div className="sub-clause-header">1.2 {i18n.t(`contract.general.tenant`)}</div>

        <div className={`${styles.row}`}>
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${TenantInputFields.NAME}`)}
            required={true}
            value={(fields?.[TenantInputFields.NAME]?.value || '') as string}
          />

          <NumberFieldReadOnly
            label={i18n.t(`contract.labels.${TenantInputFields.ORGANIZATION_NUMBER}`)}
            required={true}
            value={(fields?.[TenantInputFields.ORGANIZATION_NUMBER]?.value || '') as number}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${TenantInputFields.ADDRESS}`)}
            required={true}
            value={(fields?.[TenantInputFields.ADDRESS]?.value || '') as string}
          />

          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${TenantInputFields.POSTAL_ADDRESS}`)}
            required={true}
            value={(fields?.[TenantInputFields.POSTAL_ADDRESS]?.value || '') as string}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${TenantInputFields.INVOICE_ADDRESS}`)}
            required={true}
            value={(fields?.[TenantInputFields.INVOICE_ADDRESS]?.value || '') as string}
          />
        </div>

        <div className="sub-clause-header">{i18n.t(`contract.general.tenant_contact`)}</div>

        <VisibleIf predicate={tenant?.mainContacts?.length > 1}>
          <div className={`${styles.row}`}>
            <TextFieldReadOnly
              value={(GetTenantContractDropdownFieldValue()) as string}
              label={('Tenant')}>
            </TextFieldReadOnly>
          </div>
        </VisibleIf>

        <div className={`${styles.row}`}>
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${TenantInputFields.CONTACT_PERSON_FIRST_NAME}`)}
            required={true}
            value={GetTenantContactFirstName()}
          />

          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${TenantInputFields.CONTACT_PERSON_LAST_NAME}`)}
            required={true}
            value={GetTenantContractLastName()}
          />
        </div>

        <div className={`${styles.row}`}>
          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${TenantInputFields.CONTACT_PERSON_PHONE_NUMBER}`)}
            value={GetTenantContractPhone()}
          />

          <TextFieldReadOnly
            label={i18n.t(`contract.labels.${TenantInputFields.CONTACT_PERSON_EMAIL}`)}
            required={true}
            value={GetTenantContractEmail()}
          />
        </div>
      </div>
    </div>
  </div>
}

type PartiesDropdownMenuProps = {
  items: Array<{
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string | number;
  }>
  onClick: (id: string) => void
};

const PartiesDropdownMenu = (props: PartiesDropdownMenuProps): MenuProps['items'] => {
  if (!props?.items)
    return [{
      key: 'none',
      label: (<div className="flex-grow">
        No results found
      </div>)
    }]

  return props.items.map(item => ({
    key: item.id,
    label: (
      <div className="flex flex-col gap-1 flex-grow" onClick={_ => props.onClick(item.id)}>
        <div>{item.firstName} {item.lastName}</div>
        <div>{item.email}</div>
      </div>
    )
  }))
}
