import './NewAgreementModal.css'

import i18n from 'src/producers/languages/i18n'
import { AgreementDigitalContractIcon, AgreementPaperContractIcon, RightArrowIcon } from 'src/assets/svg'
import { Modal, ThumbnailCard, ThumbnailCardBody, ThumbnailCardFooter, ThumbnailCardHeader } from 'src/components'
import { createAgreement } from 'src/pages/Dashboard/NewAgreementModal/NewAgreementModal.createAgreement'
import { Button } from '@c11/components.button'
import styles from '../styles.module.css'
import { createShoppingMallContract } from 'src/pages/Dashboard/NewAgreementModal/createShoppingMallContract'
import { createParkingContract } from 'src/pages/Dashboard/NewAgreementModal/createParkingContract'
import { createPaperContract } from 'src/pages/Dashboard/NewAgreementModal/createPaperContract'

export const NewAgreementModal: view = ({
  trigger = observe.modal.newAgreement,
  modal = update.modal,
  triggerCreateAgreement = update.pages.dashboard.triggers[param.agreementType],
  t = get.dashboard.triggers.createPaperContract,
  triggerCreateContract = update.dashboard.triggers[param.flow],
  getCreateContract = get.dashboard.triggers[param.flow],
  paperContractsFlag = observe.featureFlags.paperContracts.main,
  parkingContractsFlag = observe.featureFlags.parkingContracts.main,
}) => {
  if (!trigger) return null

  return (
    <Modal title={i18n.t('dashboard.modal_agreement_title')} width={700} cancelButtonHidden="true">
      <section className={`${styles.cardOptionsModal}`}>
        <div className="flex flex-row gap-8 place-items-start">
          <ThumbnailCard className={`${styles.option}`}>
            <ThumbnailCardHeader
              key="tch-1"
              className="font-robotoSlab flex-col-reverse"
              title={i18n.t('dashboard.agreement_option_digital')}>
              <AgreementDigitalContractIcon width={70} height={70} />
            </ThumbnailCardHeader>
            <ThumbnailCardBody key="tcb-1">
              <p className="text-gray-600">{i18n.t('dashboard.agreement_desc_digital')}</p>
            </ThumbnailCardBody>
            <ThumbnailCardFooter key="tcf-1">
              <Button
                isDisabled={true}
                className={`${styles.modalSecondaryButton}`}
                onClick={() => {
                  triggerCreateContract.set(true, { flow: 'createShoppingMallContract' })
                  modal.remove()
                }}>
                <RightArrowIcon />
                <span className="ml-2"> {i18n.t('dashboard.agreement_option_button')}</span>
              </Button>
            </ThumbnailCardFooter>
          </ThumbnailCard>

          {paperContractsFlag && (
            <ThumbnailCard className={`${styles.option}`}>
              <ThumbnailCardHeader
                key="tch-2"
                className="font-robotoSlab flex-col-reverse"
                title={i18n.t('dashboard.agreement_option_paper')}>
                <AgreementPaperContractIcon width={70} height={70} />
              </ThumbnailCardHeader>
              <ThumbnailCardBody key="tcb-2">
                <p className="text-gray-600">{i18n.t('dashboard.agreement_desc_paper')}</p>
              </ThumbnailCardBody>
              <ThumbnailCardFooter key="tcf-2">
                <Button
                  isDisabled={true}
                  className={`${styles.modalSecondaryButton}`}
                  onClick={() => {
                    triggerCreateContract.set(true, { flow: 'createPaperContract' })
                    modal.remove()
                  }}>
                  <RightArrowIcon />
                  <span className="ml-2">{i18n.t('dashboard.agreement_option_button')}</span>
                </Button>
              </ThumbnailCardFooter>
            </ThumbnailCard>
          )}
          {parkingContractsFlag && (
            <ThumbnailCard className={`${styles.option}`}>
              <ThumbnailCardHeader
                key="tch-2"
                className="font-robotoSlab flex-col-reverse"
                title={i18n.t('dashboard.agreement_option_parking')}>
                <AgreementPaperContractIcon width={70} height={70} />
              </ThumbnailCardHeader>
              <ThumbnailCardBody key="tcb-2">
                <p className="text-gray-600">{i18n.t('dashboard.agreement_desc_parking')}</p>
              </ThumbnailCardBody>
              <ThumbnailCardFooter key="tcf-2">
                <Button
                  isDisabled={true}
                  className={`${styles.modalSecondaryButton}`}
                  onClick={() => {
                    triggerCreateContract.set(true, { flow: 'createParkingContract' })
                    modal.remove()
                  }}>
                  <RightArrowIcon />
                  <span className="ml-2">{i18n.t('dashboard.agreement_option_button')}</span>
                </Button>
              </ThumbnailCardFooter>
            </ThumbnailCard>
          )}
        </div>
      </section>
    </Modal>
  )
}

NewAgreementModal.producers([createShoppingMallContract, createParkingContract, createPaperContract])
