import { RadioButton, RadioButtonGroup, Spinner } from '@c11/components'
import { v4 } from 'uuid'
import { DatePicker, Dropdown, Menu, MenuProps } from 'antd'
import moment from 'moment'
import { DropdownField } from 'src/components_generic/DropdownField'
import {
  AutocalculatedFieldWithOverride,
  AutocalculatedFieldWithOverrideReadOnly
} from 'src/components_generic/NumberField'
import { TextFieldReadOnly } from 'src/components_generic/TextField'
import { VisibleIf } from 'src/components_generic/visible-if'
import i18n from 'src/producers/languages/i18n'
import { DateFieldReadOnly, DateField } from 'src/components_generic/DateField'
import { NumberFormatterFactory, NumberFormatterNew } from 'src/components_generic/number-formatter'
import styles from '../../../../../styles.module.css'
import { VisibleIfInvalidField } from 'src/pages/ContractV2/components/VisibleIfInvalidField'
import {
  RentalPeriodShoppingMallInputFields
} from 'src/pages/ContractV2/DigitalContract/ContractTab/templates/shoppingMall/clauses/RentalPeriod'

enum SecurityInputFields {
  // SECURITY_FORM = "securityAlternativeAdditionalInfo",
  IS_PROVIDED = 'securityIsProvided',
  SECURITY_VALUE = 'securityAmount',
  SECURITY_DEADLINE = 'securityDeadline',
  SECURITY_TYPE = 'securityType'
}

enum TenantSecurityType {
  CORPORATE_GUARANTEE = 'corporate-guarantee',
  BANK_GUARANTEE_CLAIM = 'bank-guarantee-claim',
  BANK_GUARANTEE_DEBTOR = 'bank-guarantee-debtor',
  DEPOSIT = 'deposit',
  OTHER = 'other'
}

const NumberFormatter = NumberFormatterFactory()

export const Security: view = ({
  isLocked = observe.contract.isLocked,
  contractStatus = observe.contract.originalv2.status
}) => {
  const isActiveContract = contractStatus === 'active'
  const displayReadOnlyView = isActiveContract || isLocked || true

  return <>
    <VisibleIf predicate={!displayReadOnlyView}>
      <SecurityComponent/>
    </VisibleIf>
    <VisibleIf predicate={displayReadOnlyView}>
      <SecurityComponentReadOnly/>
    </VisibleIf>
  </>
}

const SecurityComponent: view = ({
  // contract = observe.contract.originalv2,
  fields = observe.contract.originalv2.fields,
  setChanges = update.contract.changes.fields[param.name],
  setMiscChanges = update.contract.changes.miscellaneous[param.name]
}) => {
  // if (!contract?.metadata?.readOnly) return null
  const IsSecurityProvided = () => fields[SecurityInputFields.IS_PROVIDED]?.value
  const GetSecurityType = () => fields[SecurityInputFields.SECURITY_TYPE]?.value || undefined
  const IsSecurityProvidedText = () => IsSecurityProvided() ? 'yes' : IsSecurityProvided() !== undefined ? 'no': undefined
  const GetSecurityDeadline = () => fields?.[SecurityInputFields.SECURITY_DEADLINE]?.value
  // const GetSecurityDeadline = () => {
  //   const value = fields[SecurityInputFields.SECURITY_DEADLINE]?.value
  //   if (!value) return
  //
  //   return moment(value)
  // }

  return <div>
    <h2>6. {i18n.t(`contract.general.security`)}</h2>
    <div className={`${styles.cardSection}`}>
      <VisibleIfInvalidField name={SecurityInputFields.IS_PROVIDED}>
        <div className="text-pink-600 text-xs ml-8">
          {i18n.t('errors.security_type_not_selected')}
        </div>
      </VisibleIfInvalidField>

      <RadioButtonGroup selectedValue={IsSecurityProvidedText()} className="flex flex-col gap-2">
        <RadioButton
          className="w-full flex-grow"
          key={v4()}
          value={'yes'}
          isDisabled={false}
          onClick={() => {
            setMiscChanges.set({ value: true }, { name: SecurityInputFields.IS_PROVIDED })
          }}
        >
          {i18n.t(`contract.fields.${SecurityInputFields.IS_PROVIDED}.yes`)}
        </RadioButton>
        <VisibleIf predicate={IsSecurityProvided()}>
          <div className={`${styles.optionItems}`}>
            <Dropdown
              menu={{
                items: SecurityDropdownItemsFactory({
                  onClick: key => setMiscChanges.set({ value: key }, { name: SecurityInputFields.SECURITY_TYPE })
                })
              }}
              trigger={['click']}
              placement="bottom"
            >
              <div className="w-full">
                <DropdownField
                  value={i18n.t(`contract.securityType.${GetSecurityType()}`)}
                />
              </div>
            </Dropdown>

            <div className="flex flex-row items-center gap-2 flex-wrap">
              <p className={`${styles.customLabel}`}>{i18n.t(`contract.labels.${SecurityInputFields.SECURITY_VALUE}`)}</p>
              <div className="w-1/2">
                <AutocalculatedFieldWithOverride
                  name=""
                  inputExtraClassNames="text-right font-bold"
                  value={
                    fields.securityAmount?.isCustom ?
                      fields.securityAmount.value :
                      NumberFormatterNew(fields.securityAmount?.value)
                  }
                  isCustom={fields.securityAmount?.isCustom}
                  onChange={value => {
                    if (!value) return
                    setChanges.merge({
                      value: value
                    }, {
                      name: 'securityAmount'
                    })
                  }}
                  onSetCustomChanged={value => {
                    setMiscChanges.merge({
                      isCustom: !fields.securityAmount?.isCustom
                    }, {
                      name: 'securityAmount'
                    })
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row items-center gap-2 flex-wrap">
              <div style={{ marginRight: '28px' }}>{i18n.t(`contract.labels.${SecurityInputFields.SECURITY_DEADLINE}`)}</div>
              <div className="w-1/2">
                <DateField
                  extraClassNames="flex-grow"
                  // label={i18n.t(`contract.labels.${SecurityInputFields.SECURITY_DEADLINE}`)}
                  name={SecurityInputFields.SECURITY_DEADLINE}
                  key={SecurityInputFields.SECURITY_DEADLINE}
                  className="w-full"
                  placeholder={i18n.t(`contract.placeholders.${SecurityInputFields.SECURITY_DEADLINE}`)}
                  value={GetSecurityDeadline()}
                  onChange={(value) => {
                    console.log(`Date changed`, value)
                    setMiscChanges.set({ value}, {name: SecurityInputFields.SECURITY_DEADLINE})
                    // setChanges.set({
                    //   value: date?.toISOString()
                    // }, {
                    //   name: SecurityInputFields.SECURITY_DEADLINE
                    // })
                  }}
                  // format={'DD.MM.YYYY'}
                />
              </div>
            </div>
          </div>
        </VisibleIf>
        <RadioButton
          className="w-full flex-grow"
          key={v4()}
          value={'no'}
          isDisabled={false}
          onClick={() => {
            setMiscChanges.set({ value: false }, { name: SecurityInputFields.IS_PROVIDED })
          }}
        >
          {i18n.t(`contract.fields.${SecurityInputFields.IS_PROVIDED}.no`)}
        </RadioButton>
      </RadioButtonGroup>
    </div>
  </div>
}

const SecurityComponentReadOnly: view = ({
  contract = observe.contract.originalv2,
  fields = observe.contract.originalv2.fields
}) => {
  const IsSecurityProvided = () => contract.fields[SecurityInputFields.IS_PROVIDED]?.value
  const GetSecurityType = () => contract.fields[SecurityInputFields.SECURITY_TYPE]?.value
  const IsSecurityProvidedText = () => IsSecurityProvided() ? 'yes' : 'no'

  return <div>
    <h2>6. {i18n.t(`contract.general.security`)}</h2>
    <div className={`${styles.cardSection}`}>
      <RadioButtonGroup selectedValue={IsSecurityProvidedText()} className="flex flex-col gap-2">
        <RadioButton
          className="w-full flex-grow"
          key={v4()}
          value={'yes'}
          isDisabled={true}
        >
          {i18n.t(`contract.fields.${SecurityInputFields.IS_PROVIDED}.yes`)}
        </RadioButton>
        <VisibleIf predicate={IsSecurityProvided()}>
          <div className={`${styles.optionItems}`}>
            <div>
              <TextFieldReadOnly
                value={i18n.t(`contract.labels.${SecurityInputFields.SECURITY_TYPE}`)}
              />
            </div>

            <div className="flex flex-row items-center gap-2 flex-wrap">
              <p className={`${styles.customLabel}`}>{i18n.t(`contract.labels.${SecurityInputFields.SECURITY_VALUE}`)}</p>
              <div className="flex-grow ml-1">
                <AutocalculatedFieldWithOverrideReadOnly
                  extraClassNames="flex-grow"
                  inputExtraClassNames="text-right font-bold"
                  value={
                    fields.securityAmount?.isCustom ?
                      fields.securityAmount.value :
                      NumberFormatter(fields.securityAmount?.value)
                  }
                  isCustom={fields.securityAmount?.isCustom}
                />
              </div>
            </div>
            <div className="flex flex-row items-center gap-2 flex-wrap">
              <div style={{ marginRight: '28px' }}>{i18n.t(`contract.labels.${SecurityInputFields.SECURITY_DEADLINE}`)}</div>
              <div className="flex-grow">
                <DateFieldReadOnly
                  value={contract.fields[SecurityInputFields.SECURITY_DEADLINE]?.value}
                />
              </div>
            </div>
          </div>
        </VisibleIf>
        <RadioButton
          className="w-full flex-grow"
          key={v4()}
          value={'no'}
          isDisabled={true}
        >
          {i18n.t(`contract.fields.${SecurityInputFields.IS_PROVIDED}.no`)}
        </RadioButton>
      </RadioButtonGroup>
    </div>
  </div>
}

type SecurityDropdownItemsProps = {
  onClick: (key: TenantSecurityType) => void
};

function SecurityDropdownItemsFactory(params: SecurityDropdownItemsProps): MenuProps['items'] {
  return [{
    key: TenantSecurityType.CORPORATE_GUARANTEE,
    label: (<div className="flex-grow" onClick={_ => params.onClick(TenantSecurityType.CORPORATE_GUARANTEE)}>
      {i18n.t(`contract.securityType.${TenantSecurityType.CORPORATE_GUARANTEE}`)}
    </div>)
  }, {
    key: TenantSecurityType.BANK_GUARANTEE_CLAIM,
    label: (<div className="flex-grow" onClick={_ => params.onClick(TenantSecurityType.BANK_GUARANTEE_CLAIM)}>
      {i18n.t(`contract.securityType.${TenantSecurityType.BANK_GUARANTEE_CLAIM}`)}
    </div>)
  }, {
    key: TenantSecurityType.BANK_GUARANTEE_DEBTOR,
    label: (<div className="flex-grow" onClick={_ => params.onClick(TenantSecurityType.BANK_GUARANTEE_DEBTOR)}>
      {i18n.t(`contract.securityType.${TenantSecurityType.BANK_GUARANTEE_DEBTOR}`)}
    </div>)
  }, {
    key: TenantSecurityType.DEPOSIT,
    label: (<div className="flex-grow" onClick={_ => params.onClick(TenantSecurityType.DEPOSIT)}>
      {i18n.t(`contract.securityType.${TenantSecurityType.DEPOSIT}`)}
    </div>)
  }, {
    key: TenantSecurityType.OTHER,
    label: (<div className="flex-grow" onClick={_ => params.onClick(TenantSecurityType.OTHER)}>
      {i18n.t(`contract.securityType.${TenantSecurityType.OTHER}`)}
    </div>)
  }]
}

